import { SupportedNonEvmChainId } from 'constants/chains'
import { useHydraChainId, useHydraHexAddress, useHydraProvider, useHydraWalletAddress } from './useAddHydraAccExtension'

type HydraWeb3 = {
  hydraChainId: SupportedNonEvmChainId
  hydraHexAddress: string | undefined
  hydraAddress: string | undefined
  hydraProvider: any
}
export function useHydraWeb3(): HydraWeb3 {
  const hydraChainId = useHydraChainId()
  const hydraHexAddress = useHydraHexAddress()
  const hydraAddress = useHydraWalletAddress()
  const hydraProvider = useHydraProvider()

  return {
    hydraChainId,
    hydraHexAddress,
    hydraAddress,
    hydraProvider,
  }
}
