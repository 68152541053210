import { createMulticall, ListenerOptions } from '@uniswap/redux-multicall'
import { useWeb3React } from '@web3-react/core'
import { SupportedChainId } from 'constants/chains'
import { useHydraMulticallContract, useInterfaceMulticall } from 'hooks/useContract'
import useBlockNumber from 'lib/hooks/useBlockNumber'
import { useHydraWeb3 } from 'lib/hooks/useHydraWeb3'
import { contractCall, isHydraChainId } from 'lib/utils/hydra'
import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'

const multicall = createMulticall()

export default multicall

function getBlocksPerFetchForChainId(chainId: number | undefined): number {
  switch (chainId) {
    case SupportedChainId.BNB:
      return 5
    default:
      return 1
  }
}

export function MulticallUpdater() {
  const { chainId } = useWeb3React()
  const selectedChainId = useAppSelector((state) => state.connection.selectedChainId)
  const latestBlockNumber = useBlockNumber()
  const contract = useInterfaceMulticall()
  const listenerOptions: ListenerOptions = useMemo(
    () => ({
      blocksPerFetch: getBlocksPerFetchForChainId(chainId),
    }),
    [chainId]
  )

  const contractMock = useHydraContractMock()

  const isHydra = isHydraChainId(selectedChainId)

  return (
    <multicall.Updater
      chainId={selectedChainId ?? undefined}
      latestBlockNumber={latestBlockNumber}
      contract={isHydra ? contractMock : contract}
      listenerOptions={listenerOptions}
    />
  )
}

function useHydraContractMock() {
  const { hydraAddress } = useHydraWeb3()
  const multicallContract = useHydraMulticallContract()

  const contractMock = useMemo(() => {
    return {
      callStatic: {
        multicall: async (chunk: { target: string; callData: string }[]) => {
          const res = { success: false, gasUsed: 0, returnData: [{ success: false, returnData: '0x' }] }

          if (!multicallContract) {
            res.returnData = chunk.map(() => ({ success: false, returnData: '0x' }))
          } else {
            // split chunks in max 100 calls to avoid error 413
            let returnData: typeof res.returnData = []
            const chunkCounter = Math.ceil(chunk.length / 100)
            for (let i = 0; i < chunkCounter; i++) {
              const shortChunk = chunk.slice(i * 100, (i + 1) * 100) // chunk of 100 calls
              const { executionResult } = await contractCall(multicallContract, 'multicall', [shortChunk], hydraAddress)

              if (executionResult?.excepted === 'None') {
                returnData = [
                  ...returnData,
                  ...shortChunk.map((_, i) => ({
                    success: executionResult.formattedOutput[1][i][0],
                    returnData: executionResult.formattedOutput[1][i][2],
                  })),
                ]
              } else {
                returnData = [
                  ...returnData,
                  ...shortChunk.map((_, i) => ({
                    success: false,
                    returnData: executionResult.formattedOutput[1][i][2],
                  })),
                ]
              }
            }

            res.returnData = returnData
          }

          return res
        },
      },
    }
  }, [hydraAddress, multicallContract])

  return contractMock
}
