import { SupportedChainId, SupportedNonEvmChainId } from 'constants/chains'

export function convertChainIdToBridgeChainId(chainId: SupportedChainId | SupportedNonEvmChainId) {
  // Bridge contract works with uiint8 chain ids hence we have to define a chain in that range :(.
  // Kinda not cool, but would have to redeploy whole bridge infrastrucure otherwise.
  if (chainId === SupportedChainId.HYDRAGON) {
    return 255
  }

  return chainId
}
