import { memo, useCallback, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  position: relative;
  display: flex;
`

const BodyRow = styled.div`
  color: ${({ theme }) => theme.textPrimary};
  font-size: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`
const CautionTriangle = styled(AlertTriangle)`
  color: ${({ theme }) => theme.accentWarning};
  flex-shrink: 0;
`

const WarningBody = styled.div`
  background-color: ${({ theme }) => theme.backgroundSurface};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  padding: 10px 20px;
  width: 348px;
  position: absolute;
  z-index: 1;
  bottom: 0px;
  right: 30px;
`

type WarningPopupProps = {
  style?: React.CSSProperties
  warningText: string
}
export const WarningPopup = memo(function WarningPopup({ warningText, style }: WarningPopupProps) {
  const [showHover, setShowHover] = useState(false)

  const onMouseEnter = useCallback(() => {
    setShowHover(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    setShowHover(false)
  }, [])

  return (
    <Wrapper style={style}>
      <CautionTriangle onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
      {showHover ? (
        <WarningBody>
          <BodyRow>{warningText}</BodyRow>
        </WarningBody>
      ) : null}
    </Wrapper>
  )
})
