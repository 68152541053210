import HistoryTable from 'components/HistoryTable/HistoryTable'
import { Pagination } from 'components/Pagination/Pagination'
import { fetchProposals } from 'lib/api/fetchProposals'
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

export default function History() {
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  const query = useQuery(['fetchProposals', page], () => fetchProposals(page, 10))

  const onPageSelect = useCallback((newPage: number) => {
    setPage(newPage)
  }, [])

  useEffect(() => {
    if (query.data?.limit) {
      setMaxPage(query.data.pageCount)
    }
  }, [query.data?.limit])

  return (
    <>
      <HistoryTable isLoading={query.isLoading} data={query.data} />
      <Pagination totalPages={maxPage} currentPage={page} onPageChange={onPageSelect} marginTop={16} />
    </>
  )
}
