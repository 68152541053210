import { ProposalsResponse } from 'lib/types/proposals'

export const fetchProposals = async (page: number, limit: number): Promise<ProposalsResponse | undefined> => {
  try {
    const response = await fetch(`https://multibridge.hydrachain.org/api/proposals?page=${page}&limit=${limit}`)
    return response.json()
  } catch (e) {
    console.error(e)
    return
  }
}
