import { SupportedChainId, SupportedNonEvmChainId } from './chains'

const ETH_FEE = process.env.REACT_APP_ETH_FEE
if (typeof ETH_FEE === 'undefined') {
  throw new Error(`REACT_APP_ETH_FEE must be a defined environment variable`)
}

const BNB_FEE = process.env.REACT_APP_BSC_FEE
if (typeof BNB_FEE === 'undefined') {
  throw new Error(`REACT_APP_BSC_FEE must be a defined environment variable`)
}

const POLYGON_FEE = process.env.REACT_APP_POLYGON_FEE
if (typeof POLYGON_FEE === 'undefined') {
  throw new Error(`REACT_APP_POLYGON_FEE must be a defined environment variable`)
}

const HYDRA_FEE = process.env.REACT_APP_HYDRA_FEE
if (typeof HYDRA_FEE === 'undefined') {
  throw new Error(`REACT_APP_HYDRA_FEE must be a defined environment variable`)
}

const HYDRAGON_FEE = process.env.REACT_APP_HYDRAGON_FEE
if (typeof HYDRAGON_FEE === 'undefined') {
  throw new Error(`REACT_APP_HYDRAGON_FEE must be a defined environment variable`)
}

export const CHAIN_FEES = {
  [SupportedChainId.MAINNET]: ETH_FEE,
  [SupportedChainId.BNB]: BNB_FEE,
  [SupportedChainId.POLYGON]: POLYGON_FEE,
  [SupportedNonEvmChainId.HYDRA]: HYDRA_FEE,
  [SupportedChainId.HYDRAGON]: HYDRAGON_FEE,
}
