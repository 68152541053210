import { BigNumber } from '@ethersproject/bignumber'
import {
  BaseVault,
  BaseVault__factory,
  Erc20,
  Erc20__factory,
  HydraBridgeEvm,
  HydraBridgeEvm__factory,
  HydraBridgeHydra__factory,
} from 'abis/types'
import { BRIDGE_ADDRESS } from 'constants/addresses'
import { EMPTY_OBJECT } from 'constants/index'
import { RPC_PROVIDERS } from 'constants/providers'
import { Interface } from 'ethers/lib/utils'
import { isHydraChainId } from 'lib/utils/hydra'
import { useEffect, useState } from 'react'
import { SelectedChainId } from 'types/chain'
import { getContract } from 'utils'

/**
 * Returns `null` if asset is mintable.
 */
export function useLiquidityForAsset(
  chainId: SelectedChainId,
  assetAddress: string | undefined,
  assetId: string | undefined
): BigNumber | null | undefined {
  const [result, setResult] = useState<Record<string, BigNumber | null>>(EMPTY_OBJECT)

  useEffect(() => {
    ;(async () => {
      if (!chainId || !assetAddress || !assetId) {
        return
      }
      try {
        // Fetching vault address
        const bridgeAddress = BRIDGE_ADDRESS[chainId as keyof typeof BRIDGE_ADDRESS]

        if (!bridgeAddress) {
          return
        }

        if (isHydraChainId(chainId)) {
          const bridgeIface = new Interface(HydraBridgeHydra__factory.abi)
          const data = bridgeIface.encodeFunctionData('assetIdToVault', [assetId])
          // Trim 0x prefix because the explorer goes kaboom.
          const response = await (
            await fetch(
              `https://explorer.hydrachain.org/api/contract/${bridgeAddress
                .substring(2)
                .toLowerCase()}/call?data=${data.substring(2)}`
            )
          ).json()
          const output = '0x' + response.executionResult.output
          const vaultAddress: string = bridgeIface.decodeFunctionResult('assetIdToVault', output)[0]

          const vaultIface = new Interface(BaseVault__factory.abi)
          const vaultCallData = vaultIface.encodeFunctionData('tokenBurnList', [assetAddress])
          const vaultResponse = await (
            await fetch(
              `https://explorer.hydrachain.org/api/contract/${vaultAddress
                .substring(2)
                .toLowerCase()}/call?data=${vaultCallData.substring(2)}`
            )
          ).json()
          const vaultOutput = '0x' + vaultResponse.executionResult.output
          const isMintable: boolean = vaultIface.decodeFunctionResult('tokenBurnList', vaultOutput)[0]

          if (isMintable) {
            setResult((prev) => ({ ...prev, [assetAddress]: null }))
            return
          }

          const erc20Iface = new Interface(Erc20__factory.abi)
          const secondData = erc20Iface.encodeFunctionData('balanceOf', [vaultAddress])
          const secondResponse = await (
            await fetch(
              `https://explorer.hydrachain.org/api/contract/${assetAddress
                .substring(2)
                .toLowerCase()}/call?data=${secondData.substring(2)}`
            )
          ).json()
          const secondOutput = '0x' + secondResponse.executionResult.output

          const balance: BigNumber = erc20Iface.decodeFunctionResult('balanceOf', secondOutput)[0]

          setResult((prev) => ({ ...prev, [assetAddress]: balance }))
          return
        }

        const provider = RPC_PROVIDERS[chainId as keyof typeof RPC_PROVIDERS]
        if (!provider) {
          return
        }

        //HYDRA_BRIDGE_EVM_ABI
        const bridgeContract = getContract(bridgeAddress, HydraBridgeEvm__factory.abi, provider) as HydraBridgeEvm
        const vaultAddress = await bridgeContract.assetIdToVault(assetId)
        const vaultContract = getContract(vaultAddress, BaseVault__factory.abi, provider) as BaseVault
        const isMintable = await vaultContract.tokenBurnList(assetAddress)
        if (isMintable) {
          setResult((prev) => ({ ...prev, [assetAddress]: null }))
          return
        }

        const tokenContract = getContract(assetAddress, Erc20__factory.abi, provider) as Erc20
        const balance = await tokenContract.balanceOf(vaultAddress)

        setResult((prev) => ({ ...prev, [assetAddress]: balance }))

        return
      } catch {
        return
      }
    })()
  }, [chainId, assetAddress, assetId])

  return result[assetAddress ?? '']
}
