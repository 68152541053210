import { CurrencyAmount } from '@uniswap/sdk-core'
import { SupportedNonEvmChainId } from 'constants/chains'
import { nativeOnChain } from 'constants/tokens'
import { parseUnits } from 'ethers/lib/utils'
import { useHydraBalance } from 'lib/hooks/useAddHydraAccExtension'
import { useMemo } from 'react'

const HYDRA_CURRENCY = nativeOnChain(SupportedNonEvmChainId.HYDRA)

export function useHydraCurrencyBalance() {
  const balance = useHydraBalance()

  return useMemo(() => {
    if (!balance) {
      return
    }

    return CurrencyAmount.fromRawAmount(HYDRA_CURRENCY, parseUnits(balance, HYDRA_CURRENCY.decimals).toString())
  }, [balance])
}
