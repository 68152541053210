import HydraGuard from 'assets/images/hydra-guard.png'
import Row from 'components/Row'
import { ReactNode } from 'react'
import { HideSmall } from 'theme'

export default function HydraGuardWrapper({ children }: { children: ReactNode }) {
  return (
    <Row justify="center" align="center">
      <HideSmall>
        <img
          alt="hydra"
          width={200}
          style={{ transform: 'scaleX(-1)', opacity: 0.15, marginRight: -45, marginTop: -40, userSelect: 'none' }}
          src={HydraGuard}
        />
      </HideSmall>
      {children}
      <HideSmall>
        <img
          alt="hydra"
          style={{ opacity: 0.15, marginLeft: -45, marginTop: -40, userSelect: 'none' }}
          width={200}
          src={HydraGuard}
        />
      </HideSmall>
    </Row>
  )
}
