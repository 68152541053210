import { NativeCurrency, Token } from '@uniswap/sdk-core'
import { SUPPORTED_TOKENS_PER_CHAIN, TOKENS_BRIDGE_ASSET_ID } from 'constants/tokens/index'
import { TokenName } from 'constants/tokens/token_names'

export function useTokenByChainIdAndAssetId(chainId: number, assetId: string): Token | NativeCurrency | undefined {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const symbol = Object.entries(TOKENS_BRIDGE_ASSET_ID).find(([_, id]) => id === assetId)?.[0]
  if (!symbol) {
    return undefined
  }

  const keySymbol: TokenName = symbol === 'WHYDRA' ? 'HYDRA' : (symbol as TokenName)
  return SUPPORTED_TOKENS_PER_CHAIN[chainId as keyof typeof SUPPORTED_TOKENS_PER_CHAIN]?.[keySymbol]
}
