import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'constants/breakpoints'
import { ProposalsResponse } from 'lib/types/proposals'
import { ReactNode } from 'react'
import { AlertTriangle } from 'react-feather'
import styled from 'styled-components/macro'
import { HeaderRow, LoadedRow, LoadingRow } from './HistoryRow'

const PAGE_SIZE = 10

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  background-color: ${({ theme }) => theme.backgroundSurface};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
`

const TokenDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  width: 100%;
`

const NoTokenDisplay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
`

function NoProposalsState({ message }: { message: ReactNode }) {
  return (
    <GridContainer>
      <HeaderRow />
      <NoTokenDisplay>{message}</NoTokenDisplay>
    </GridContainer>
  )
}

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingHistoryTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <HeaderRow />
      <TokenDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TokenDataContainer>
    </GridContainer>
  )
}

type HistoryTableProps = {
  isLoading: boolean
  data: ProposalsResponse | undefined
}
export default function HistoryTable(props: HistoryTableProps) {
  /* loading and error state */
  if (props.isLoading && !props.data?.proposals) {
    return <LoadingHistoryTable rowCount={PAGE_SIZE} />
  } else if (!props.data?.proposals) {
    return (
      <NoProposalsState
        message={
          <>
            <AlertTriangle size={16} />
            An error occurred loading transactions. Please try again.
          </>
        }
      />
    )
  } else if (props.data.proposals?.length === 0) {
    return <NoProposalsState message="No proposals found" />
  } else {
    return (
      <GridContainer>
        <HeaderRow />
        <TokenDataContainer>
          {props.data.proposals.map((proposal, index) => (
            <LoadedRow key={index} proposal={proposal} />
          ))}
        </TokenDataContainer>
      </GridContainer>
    )
  }
}
