import { SupportedChainId, SupportedNonEvmChainId } from 'constants/chains'
import { base58 } from 'ethers/lib/utils'

export const trimHexPrefix = (hex: string | undefined): string | undefined => {
  if (hex?.startsWith('0x')) {
    return hex.substring(2)
  }
  return hex
}

export const hydraToHexAddress = (address: string | undefined, noPrefix?: boolean): string | undefined => {
  if (!address || address.length > 34) {
    return address
  }

  const hex = Buffer.from(base58.decode(address)).toString('hex').substring(2, 42)
  return noPrefix ? hex : '0x' + hex
}

export const getChainId = (network: string | undefined): SupportedNonEvmChainId | undefined => {
  if (!network) return undefined

  // TODO: Add support for testnet
  return network.toLowerCase() === 'mainnet' ? SupportedNonEvmChainId.HYDRA : undefined
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenHydraAddress(address: string, chars = 4): string {
  const parsed = address
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(34 - chars)}`
}

export function isHydraChainId(chainId: SupportedNonEvmChainId | SupportedChainId | null): boolean {
  return !!SupportedNonEvmChainId[chainId ?? 0]
}

export const contractCall = async (
  contract: any,
  method: string,
  methodArgs: any,
  senderAddress: string | undefined
) => {
  const tx = await contract.call(method, {
    methodArgs,
    senderAddress,
  })

  return tx
}

export const contractSend = async (
  contract: any,
  method: string,
  methodArgs: any,
  senderAddress: string,
  gasLimit = 250000,
  amountHYDRA = 0
) => {
  const tx = await contract.send(method, {
    methodArgs,
    gasLimit,
    senderAddress,
    amount: amountHYDRA,
  })

  return tx
}

export const getHydraContract = (hydraweb3Extension: any, contractAddress: string, abi: any) => {
  try {
    const contract = hydraweb3Extension.Contract(contractAddress, abi)

    return contract
  } catch {
    // silent
    return null
  }
}
