import { Token } from '@uniswap/sdk-core'
import { SupportedChainId } from 'constants/chains'

export const WHYDRA_ETH = new Token(
  SupportedChainId.MAINNET,
  '0x96C3530BFd0a906a123A4e26CEbB635636B41f9d',
  18,
  'HYDRA',
  'Hydra'
)
export const WETH_ETH = new Token(
  SupportedChainId.MAINNET,
  '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  18,
  'WETH',
  'Wrapped Ether'
)
export const DAI_ETH = new Token(
  SupportedChainId.MAINNET,
  '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const WBTC_ETH = new Token(
  SupportedChainId.MAINNET,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped Bitcoin'
)
export const EVED_ETH = new Token(
  SupportedChainId.MAINNET,
  '0x5aaEFe84E0fB3DD1f0fCfF6fA7468124986B91bd',
  18,
  'EVED',
  'Evedo'
)
export const LOC_ETH = new Token(
  SupportedChainId.MAINNET,
  '0x5e3346444010135322268a4630d2ED5F8D09446c',
  18,
  'LOC',
  'LockTrip'
)
export const CHANGE_ETH = new Token(
  SupportedChainId.MAINNET,
  '0x7051faED0775f664a0286Af4F75ef5ed74e02754',
  18,
  'CHANGE',
  'Changex'
)
export const GOMT_ETH = new Token(
  SupportedChainId.MAINNET,
  '0xFB1172b050BcC798e37AE8abF620Cc528e771162',
  8,
  'GOMT',
  'GoMeat'
)
export const USDC_ETH = new Token(
  SupportedChainId.MAINNET,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USDC'
)
export const USDT_ETH = new Token(
  SupportedChainId.MAINNET,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'USDT'
)
export const BUSD_ETH = new Token(
  SupportedChainId.MAINNET,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'BUSD'
)
export const BNB_ETH = new Token(
  SupportedChainId.MAINNET,
  '0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
  18,
  'WBNB',
  'Wrapped BNB'
)
export const MATIC_ETH = new Token(
  SupportedChainId.MAINNET,
  '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
  18,
  'WMATIC',
  'Wrapped MATIC'
)
