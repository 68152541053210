import { SupportedNonEvmChainId } from 'constants/chains'
import { SelectedChainId } from 'types/chain'
import { isAddress, isHydraAddress } from 'utils'

export function useAddress(value: string, chainId?: SelectedChainId) {
  if (SupportedNonEvmChainId[chainId ?? 0]) {
    return isHydraAddress(value)
  }

  return isAddress(value)
}
