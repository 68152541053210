import { Token } from '@uniswap/sdk-core'
import { SupportedChainId } from 'constants/chains'

export const WETH_POLYGON = new Token(
  SupportedChainId.POLYGON,
  '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  18,
  'WETH',
  'Wrapped Ether'
)
export const DAI_POLYGON = new Token(
  SupportedChainId.POLYGON,
  '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const WBTC_POLYGON = new Token(
  SupportedChainId.POLYGON,
  '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
  8,
  'WBTC',
  'Wrapped Bitcoin'
)

export const USDC_POLYGON = new Token(
  SupportedChainId.POLYGON,
  '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
  6,
  'USDC',
  'USDC'
)
export const USDT_POLYGON = new Token(
  SupportedChainId.POLYGON,
  '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  6,
  'USDT',
  'USDT'
)
export const BUSD_POLYGON = new Token(
  SupportedChainId.POLYGON,
  '0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7',
  18,
  'BUSD',
  'BUSD'
)
export const BNB_POLYGON = new Token(
  SupportedChainId.POLYGON,
  '0x3BA4c387f786bFEE076A58914F5Bd38d668B42c3',
  18,
  'WBNB',
  'Wrapped BNB'
)
export const MATIC_POLYGON = new Token(
  SupportedChainId.POLYGON,
  '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  18,
  'WMATIC',
  'Wrapped MATIC'
)
