import HydraLogo from 'assets/images/hydra-logo.png'
import { Box } from 'components/Layout/Box'
import { Row } from 'components/Layout/Flex'
import Web3Status from 'components/Web3Status'
import { ReactNode } from 'react'
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ChainSelector } from './ChainSelector'
import * as styles from './style.css'

const Nav = styled.nav`
  padding: 20px 12px;
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 2;
`

const LogoContainer = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }

  position: relative;
`

type MenuItemProps = {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
}

const MenuItem = ({ href, id, isActive, children }: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: 'none' }}
    >
      {children}
    </NavLink>
  )
}

export const PageTabs = () => {
  const { pathname } = useLocation()

  return (
    <>
      <MenuItem href="/" isActive={pathname === '/'}>
        Bridge
      </MenuItem>
      <MenuItem href="/history" isActive={pathname.startsWith('/history')}>
        History
      </MenuItem>
    </>
  )
}

const Navbar = () => {
  return (
    <Nav>
      <Box display="flex" height="full" flexWrap="nowrap">
        <Box className={styles.leftSideContainer}>
          <Box className={styles.logoContainer}>
            <LogoContainer style={{ width: 50, height: 50, borderRadius: '100%' }}>
              <img alt="HydraChain" width="50px" src={HydraLogo} />
            </LogoContainer>
          </Box>
          <Row gap={{ xl: '0', xxl: '8' }} display={{ sm: 'none', lg: 'flex' }}>
            <PageTabs />
          </Row>
          <Box display={{ sm: 'flex', lg: 'none' }}>
            <ChainSelector leftAlign={true} />
          </Box>
        </Box>
        <Box className={styles.rightSideContainer}>
          <Row gap="12">
            <Box display={{ sm: 'none', lg: 'flex' }}>
              <ChainSelector />
            </Box>
            <Web3Status />
          </Row>
        </Box>
      </Box>
    </Nav>
  )
}

export default Navbar
