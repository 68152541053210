import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { useAccount } from 'hooks/useAccount'
import useSelectedChainId from 'hooks/useSelectedChainId'
import useTokensForChain from 'hooks/useTokensForChain'
import { useTokenBalancesWithLoadingIndicator } from 'lib/hooks/useCurrencyBalance'
import { useMemo } from 'react'

export {
  default as useCurrencyBalance,
  useCurrencyBalances,
  useCurrencyBalanceString,
  useNativeCurrencyBalances,
  useTokenBalance,
  useTokenBalances,
  useTokenBalancesWithLoadingIndicator,
} from 'lib/hooks/useCurrencyBalance'

// mimics useAllBalances
export function useAllTokenBalances(): [{ [tokenAddress: string]: CurrencyAmount<Token> | undefined }, boolean] {
  const selectedChainId = useSelectedChainId()
  const account = useAccount()

  const tokensForChain = useTokensForChain(selectedChainId)
  const allTokensArray = useMemo(
    () => (tokensForChain ? Object.values(tokensForChain).map((t) => t.wrapped) : []),
    [tokensForChain]
  )

  const [balances, balancesIsLoading] = useTokenBalancesWithLoadingIndicator(account ?? undefined, allTokensArray)
  return [balances ?? {}, balancesIsLoading]
}
