import { SupportedNonEvmChainId } from 'constants/chains'
import { formatUnits } from 'ethers/lib/utils'
import { getChainId, hydraToHexAddress } from 'lib/utils/hydra'
import { useMemo } from 'react'
import useHydra from './useHydra'

export function isEmptyObj(obj: Record<string, any>) {
  if (!obj) {
    return true
  }
  return Object.keys(obj).length === 0
}

export interface Account {
  address: string
  balance: string
  loggedIn: boolean
  name: string
  network: string
}

export let account: Account
export let hydraweb3RPC: any

export default function useAddHydraAccExtension(walletExtension: any, hydraweb3Extension: any) {
  if (!isEmptyObj(walletExtension)) {
    account = walletExtension
  }
  if (!isEmptyObj(hydraweb3Extension)) {
    hydraweb3RPC = hydraweb3Extension
  }
}

export function useHydraProvider() {
  const { hydraweb3Extension } = useHydra()
  const isEmpty = isEmptyObj(hydraweb3Extension)

  return isEmpty ? undefined : hydraweb3Extension
}

export function useHydraAccount() {
  const { walletExtension } = useHydra()
  return [walletExtension]
}

export function useHydraWalletAddress(): string | undefined {
  const [account] = useHydraAccount()
  return account?.address || undefined
}

export function useHydraHexAddress(noPrefix?: boolean | undefined) {
  const addr = useHydraWalletAddress()
  const hexAddr = useMemo(() => (addr ? hydraToHexAddress(addr, noPrefix) : undefined), [addr, noPrefix])
  return hexAddr
}

export function useHydraChainId(): SupportedNonEvmChainId {
  const [account] = useHydraAccount()
  return getChainId(account?.network) ?? SupportedNonEvmChainId.HYDRA
}

export function useHydraBalance(): string | undefined {
  const [account] = useHydraAccount()
  const balance: string | undefined = account?.balance
  return useMemo(() => (balance ? formatUnits(balance, 8) : undefined), [balance])
}
