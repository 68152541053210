import { Token } from '@uniswap/sdk-core'
import { SupportedChainId } from 'constants/chains'

export const WETH_BNB = new Token(
  SupportedChainId.BNB,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'WETH',
  'Wrapped Ether'
)
export const DAI_BNB = new Token(
  SupportedChainId.BNB,
  '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const WBTC_BNB = new Token(
  SupportedChainId.BNB,
  '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  18,
  'WBTC',
  'Wrapped Bitcoin'
)

export const USDC_BNB = new Token(
  SupportedChainId.BNB,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'USDC'
)
export const USDT_BNB = new Token(
  SupportedChainId.BNB,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'USDT'
)
export const BUSD_BNB = new Token(
  SupportedChainId.BNB,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'BUSD'
)
export const BNB_BNB = new Token(
  SupportedChainId.BNB,
  '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  18,
  'WBNB',
  'Wrapped BNB'
)
export const MATIC_BNB = new Token(
  SupportedChainId.BNB,
  '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
  18,
  'WMATIC',
  'Wrapped MATIC'
)
