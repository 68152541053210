import { useWeb3React } from '@web3-react/core'
import { getNonEvmChainInfo } from 'constants/chainInfo'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import { isHydraChainId } from 'lib/utils/hydra'
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'state/hooks'

const MISSING_PROVIDER = Symbol()
const BlockNumberContext = createContext<
  | {
      value?: number
      fastForward(block: number): void
    }
  | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

function useBlockNumberContext() {
  const blockNumber = useContext(BlockNumberContext)
  if (blockNumber === MISSING_PROVIDER) {
    throw new Error('BlockNumber hooks must be wrapped in a <BlockNumberProvider>')
  }
  return blockNumber
}

/** Requires that BlockUpdater be installed in the DOM tree. */
export default function useBlockNumber(): number | undefined {
  return useBlockNumberContext().value
}

export function useFastForwardBlockNumber(): (block: number) => void {
  return useBlockNumberContext().fastForward
}

let interval: NodeJS.Timeout | undefined

export function BlockNumberProvider({ children }: { children: ReactNode }) {
  const { provider } = useWeb3React()
  const selectedChainId = useAppSelector((state) => state.connection.selectedChainId)
  const [{ chainId, block }, setChainBlock] = useState<{ chainId?: number; block?: number }>({
    chainId: selectedChainId ?? undefined,
  })
  const hydraExplorerUrl = getNonEvmChainInfo(selectedChainId ?? undefined)?.explorer

  const onBlock = useCallback(
    (block: number) => {
      setChainBlock((chainBlock) => {
        if (chainBlock.chainId === selectedChainId) {
          if (!chainBlock.block || chainBlock.block < block) {
            return { chainId: selectedChainId, block }
          }
        }
        return chainBlock
      })
    },
    [selectedChainId, setChainBlock]
  )

  const fetchHydraBlock = useCallback(() => {
    if (!hydraExplorerUrl) {
      return
    }

    fetch(hydraExplorerUrl + 'api/info')
      .then((r) => r.json())
      .then((info) => {
        // Probably unnecessary check, but just in case
        if (typeof info.height === 'number') {
          onBlock(info.height)
        }
      })
      .catch((error) => console.error('Failed to get block number for hydra:', error))
  }, [hydraExplorerUrl, onBlock])

  const windowVisible = useIsWindowVisible()
  useEffect(() => {
    let stale = false

    if (isHydraChainId(selectedChainId) && windowVisible) {
      if (interval) {
        clearInterval(interval)
        interval = undefined
      }

      // If chainId hasn't changed, don't clear the block. This prevents re-fetching still valid data.
      setChainBlock((chainBlock) =>
        chainBlock.chainId === selectedChainId ? chainBlock : { chainId: selectedChainId ?? undefined }
      )

      fetchHydraBlock()
      if (!interval) {
        interval = setInterval(() => fetchHydraBlock(), 15000)
      }
    } else if (provider && selectedChainId && windowVisible) {
      // If chainId hasn't changed, don't clear the block. This prevents re-fetching still valid data.
      setChainBlock((chainBlock) =>
        chainBlock.chainId === selectedChainId ? chainBlock : { chainId: selectedChainId }
      )

      provider
        .getBlockNumber()
        .then((block) => {
          if (!stale) onBlock(block)
        })
        .catch((error) => {
          console.error(`Failed to get block number for chainId ${selectedChainId}`, error)
        })

      provider.on('block', onBlock)
      return () => {
        stale = true
        provider.removeListener('block', onBlock)
      }
    }

    return void 0
  }, [provider, onBlock, setChainBlock, windowVisible, selectedChainId, fetchHydraBlock])

  const value = useMemo(
    () => ({
      value: chainId === selectedChainId ? block : undefined,
      fastForward: (update: number) => {
        if (block && update > block) {
          setChainBlock({ chainId: selectedChainId ?? undefined, block: update })
        }
      },
    }),
    [selectedChainId, block, chainId]
  )
  return <BlockNumberContext.Provider value={value}>{children}</BlockNumberContext.Provider>
}
