import { useToken } from 'hooks/Tokens'
import {
  ApproveTransactionInfo,
  DepositTransactionInfo,
  TransactionInfo,
  TransactionType,
} from '../../state/transactions/types'

function ApprovalSummary({ info }: { info: ApproveTransactionInfo }) {
  const token = useToken(info.tokenAddress)

  return <div>Approve {token?.symbol}</div>
}

function DepositSummary({ info }: { info: DepositTransactionInfo }) {
  return (
    <div>
      Deposit {info.formattedAmount} {info.tokenSymbol}
    </div>
  )
}

export function TransactionSummary({ info }: { info: TransactionInfo }) {
  switch (info.type) {
    case TransactionType.APPROVAL:
      return <ApprovalSummary info={info} />
    case TransactionType.DEPOSIT:
      return <DepositSummary info={info} />
  }
}
