import { Token } from '@uniswap/sdk-core'
import { SupportedNonEvmChainId } from 'constants/chains'

export const WHYDRA_HYDRA = new Token(
  SupportedNonEvmChainId.HYDRA,
  '6d9115a21863ce31b44cd231e4c4ccc87566222f',
  8,
  'WHYDRA',
  'Wrapped Hydra'
)
export const WETH_HYDRA = new Token(
  SupportedNonEvmChainId.HYDRA,
  '0xab9e970de9ba9ec2c64a7f7f911da44b750ecf6a',
  18,
  'WETH',
  'Wrapped Ether'
)
export const DAI_HYDRA = new Token(
  SupportedNonEvmChainId.HYDRA,
  '0xabc2cd00700e06922bcf30fe0ad648507113cc56',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const WBTC_HYDRA = new Token(
  SupportedNonEvmChainId.HYDRA,
  '0x27a459ac47204f1e7fc5c48ffeafa990af244e13',
  8,
  'WBTC',
  'Wrapped Bitcoin'
)
export const EVED_HYDRA = new Token(
  SupportedNonEvmChainId.HYDRA,
  '0x9cbbbc9d192fc81225a70f88877169b94d663f8b',
  18,
  'EVED',
  'Evedo'
)
export const LOC_HYDRA = new Token(
  SupportedNonEvmChainId.HYDRA,
  '0x4ab26aaa1803daa638910d71075c06386e391147',
  8,
  'LOC',
  'LockTrip'
)
export const CHANGE_HYDRA = new Token(
  SupportedNonEvmChainId.HYDRA,
  '0xbd3c617d271b3467bd9b83dda73c9288de2fb0c9',
  18,
  'CHANGE',
  'Changex'
)
export const GOMT_HYDRA = new Token(
  SupportedNonEvmChainId.HYDRA,
  '0x7b8dd012ec9e1f11e008376ef21d29ba4cc78e24',
  8,
  'GOMT',
  'GoMeat'
)
export const USDC_HYDRA = new Token(
  SupportedNonEvmChainId.HYDRA,
  '0xe4b8164dccf3489f66124cace1570dc35b58fc90',
  6,
  'USDC',
  'USDC'
)
export const USDT_HYDRA = new Token(
  SupportedNonEvmChainId.HYDRA,
  '0x932917c89717c809f67ebb421e24fd04d7a96d33',
  6,
  'USDT',
  'USDT'
)
export const BUSD_HYDRA = new Token(
  SupportedNonEvmChainId.HYDRA,
  '0x0c404645b9e36b6cf9bcaf2747e472334b268e9d',
  18,
  'BUSD',
  'BUSD'
)
