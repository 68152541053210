import { LoadingBubble } from 'components/LoadingBubble'
import { MAX_WIDTH_MEDIA_BREAKPOINT, SMALL_MEDIA_BREAKPOINT } from 'constants/breakpoints'
import { getChainInfo, getNonEvmChainInfo } from 'constants/chainInfo'
import { DEFAULT_LOCALE } from 'constants/locales'
import { formatUnits } from 'ethers/lib/utils'
import { useTokenByChainIdAndAssetId } from 'hooks/useTokenByChainIdAndAssetId'
import { Proposal, ProposalStatus } from 'lib/types/proposals'
import formatLocaleNumber from 'lib/utils/formatLocaleNumber'
import { isHydraChainId } from 'lib/utils/hydra'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import { ExternalLink } from 'theme'
import { shortenTxHash } from 'utils'

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledHistoryRow = styled.div<{
  first?: boolean
  last?: boolean
  loading?: boolean
}>`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  font-size: 16px;
  line-height: 24px;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  ${({ first, last }) => css`
    height: ${first || last ? '72px' : '64px'};
    padding-top: ${first ? '8px' : '0px'};
    padding-bottom: ${last ? '8px' : '0px'};
  `}
  padding-left: 12px;
  padding-right: 12px;

  width: 100%;
`

const StyledHeaderRow = styled(StyledHistoryRow)`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.backgroundOutline};
  border-radius: 8px 8px 0px 0px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  height: 48px;
  line-height: 16px;
  padding: 0px 12px;
  width: 100%;
  justify-content: center;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

const DataCell = styled(Cell)`
  justify-content: center;
  min-width: 80px;
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
`
const TxCell = styled(DataCell)`
  font-size: 14px;
  min-width: 130px;
  padding: 0px 4px;
`
const ChainCell = styled(Cell)`
  justify-content: flex-start;
  padding: 0px 8px;
  min-width: 136px;
`
const StatusCell = styled(DataCell)<{ backgroundColor?: string }>`
  padding: 2px 0;
  border-radius: 8px;
  min-width: 120px;
  ${({ backgroundColor }) => css`
    background-color: ${backgroundColor};
  `}
`
const AmountCell = styled(DataCell)`
  min-width: 150px;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
`

const StyledLink = styled(ExternalLink)`
  text-decoration: none;
`

const MediumLoadingBubble = styled(LoadingBubble)`
  width: 65%;
`

const IconLoadingBubble = styled(LoadingBubble)`
  border-radius: 50%;
  width: 24px;
`

const Logo = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 12px;
`

type StatusConfigT = {
  text: string
  color: string
}
const StatusConfig: Record<ProposalStatus, StatusConfigT> = {
  0: { text: 'Submitted', color: '#0071ea7f' },
  1: { text: 'Pending', color: '#ffc1077f' },
  2: { text: 'Confirmed', color: '#6feb8c7f' },
  3: { text: 'Completed', color: '#2188387f' },
  4: { text: 'Cancelled', color: '#dc35457f' },
}

/* Token Row: skeleton row component */
function HistoryRow({
  header,
  from,
  to,
  status,
  amount,
  lockTx,
  executeTx,
  ...rest
}: {
  header?: boolean
  from: ReactNode
  to: ReactNode
  status: ReactNode
  amount: ReactNode
  lockTx: ReactNode
  executeTx: ReactNode
  first?: boolean
  loading?: boolean
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <ChainCell>{from}</ChainCell>
      <ChainCell>{to}</ChainCell>
      <StatusCell>{status}</StatusCell>
      <AmountCell>{amount}</AmountCell>
      <TxCell>{lockTx}</TxCell>
      <TxCell>{executeTx}</TxCell>
    </>
  )
  if (header) return <StyledHeaderRow>{rowCells}</StyledHeaderRow>
  return <StyledHistoryRow {...rest}>{rowCells}</StyledHistoryRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <HistoryRow header from="From" to="To" status="Status" amount="Amount" lockTx="Lock Tx" executeTx="Execute Tx" />
  )
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <HistoryRow
      loading
      from={
        <ChainCell>
          <IconLoadingBubble />
          <MediumLoadingBubble />
        </ChainCell>
      }
      to={
        <ChainCell>
          <IconLoadingBubble />
          <MediumLoadingBubble />
        </ChainCell>
      }
      status={<MediumLoadingBubble />}
      amount={<LoadingBubble />}
      lockTx={<LoadingBubble />}
      executeTx={<LoadingBubble />}
      {...props}
    />
  )
}

type LoadedRowProps = {
  proposal: Proposal
}

/* Loaded State: row component with proposal information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const originChainInfo = isHydraChainId(Number(props.proposal.originChainId))
    ? getNonEvmChainInfo(Number(props.proposal.originChainId))
    : getChainInfo(Number(props.proposal.originChainId))

  const destinationChainInfo = isHydraChainId(Number(props.proposal.destinationChainId))
    ? getNonEvmChainInfo(Number(props.proposal.destinationChainId))
    : getChainInfo(Number(props.proposal.destinationChainId))

  // Using destination because of decimals vault
  const originToken = useTokenByChainIdAndAssetId(Number(props.proposal.originChainId), props.proposal.assetId)
  const destinationToken = useTokenByChainIdAndAssetId(
    Number(props.proposal.destinationChainId),
    props.proposal.assetId
  )

  return (
    <div ref={ref}>
      <HistoryRow
        from={
          <>
            <Logo src={originChainInfo?.logoUrl} alt={originChainInfo?.label} />
            {originChainInfo?.label}
          </>
        }
        to={
          <>
            <Logo src={destinationChainInfo?.logoUrl} alt={destinationChainInfo?.label} />
            {destinationChainInfo?.label}
          </>
        }
        status={
          <StatusCell backgroundColor={StatusConfig[props.proposal.status]?.color}>
            {StatusConfig[props.proposal.status]?.text ?? '-'}
          </StatusCell>
        }
        amount={
          originToken && destinationToken
            ? formatLocaleNumber({
                number: Number(formatUnits(props.proposal.amount, destinationToken.decimals)),
                sigFigs: 4,
                locale: DEFAULT_LOCALE,
              }) + ` ${originToken.symbol}`
            : '-'
        }
        lockTx={
          originChainInfo ? (
            <StyledLink href={`${originChainInfo.explorer}tx/${props.proposal.txHash}`}>
              {shortenTxHash(props.proposal.txHash)}
            </StyledLink>
          ) : (
            '-'
          )
        }
        executeTx={
          props.proposal.executionTxHash && destinationChainInfo ? (
            <StyledLink href={`${destinationChainInfo.explorer}tx/${props.proposal.executionTxHash}`}>
              {shortenTxHash(props.proposal.executionTxHash)}
            </StyledLink>
          ) : (
            '-'
          )
        }
        first={true}
        last={false}
      />
    </div>
  )
})

LoadedRow.displayName = 'LoadedRow'
