import { useWeb3React } from '@web3-react/core'
import { TokenWarningRedIcon } from 'components/Common/icons'
import { Portal } from 'components/Common/Portal'
import { Box } from 'components/Layout/Box'
import { Column, Row } from 'components/Layout/Flex'
import { getChainInfo, getNonEvmChainInfo } from 'constants/chainInfo'
import {
  NETWORK_SELECTOR_CHAINS,
  NON_EVM_NETWORK_SELECTOR_CHAINS,
  SupportedChainId,
  SupportedNonEvmChainId,
} from 'constants/chains'
import { subhead } from 'css/common.css'
import { themeVars } from 'css/sprinkles.css'
import { useIsMobile } from 'hooks/useIsMobile'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useSelectChain from 'hooks/useSelectChain'
import useSyncChainQuery from 'hooks/useSyncChainQuery'
import { useCallback, useRef, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useConnectHydraWithModal } from 'state/application/hooks'
import { setSelectedChainId } from 'state/connection/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useTheme } from 'styled-components/macro'
import { SelectedChainId } from 'types/chain'
import * as styles from './ChainSelector.css'
import { ChainSelectorRow, NonEvmChainSelectorRow } from './ChainSelectorRow'
import { NavDropdown } from './NavDropdown'

interface ChainSelectorProps {
  leftAlign?: boolean
  showLabel?: boolean
}

export const ChainSelector = ({ leftAlign, showLabel }: ChainSelectorProps) => {
  const { chainId } = useWeb3React()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const isMobile = useIsMobile()

  const theme = useTheme()

  const ref = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsOpen(false), [modalRef])

  const selectedChainId = useAppSelector((state) => state.connection.selectedChainId)

  const info =
    selectedChainId === SupportedNonEvmChainId.HYDRA
      ? getNonEvmChainInfo(SupportedNonEvmChainId.HYDRA)
      : chainId
      ? getChainInfo(chainId)
      : undefined

  const selectChain = useSelectChain()
  useSyncChainQuery()

  const dispatch = useAppDispatch()

  const [pendingChainId, setPendingChainId] = useState<SupportedChainId | undefined>(undefined)

  const onSelectChain = useCallback(
    async (targetChainId: SupportedChainId) => {
      setPendingChainId(targetChainId)
      await selectChain(targetChainId)
      dispatch(setSelectedChainId(targetChainId))

      setPendingChainId(undefined)
      setIsOpen(false)
    },
    [selectChain, setIsOpen, dispatch]
  )

  const connectHydraWithModal = useConnectHydraWithModal()

  const onSelectNonEvmChain = useCallback(() => {
    connectHydraWithModal()
    dispatch(setSelectedChainId(SupportedNonEvmChainId.HYDRA))

    setIsOpen(false)
  }, [connectHydraWithModal, dispatch])

  // TODO Check if this can be removed
  if (!chainId) {
    return null
  }

  const isSupported = !!info

  const dropdown = (
    <NavDropdown top="56" left={leftAlign ? '0' : 'auto'} right={leftAlign ? 'auto' : '0'} ref={modalRef}>
      <Column paddingX="8">
        {NETWORK_SELECTOR_CHAINS.map((chainId: SupportedChainId) => (
          <ChainSelectorRow
            onSelectChain={onSelectChain}
            targetChain={chainId}
            key={chainId}
            isPending={chainId === pendingChainId}
          />
        ))}
        {NON_EVM_NETWORK_SELECTOR_CHAINS.map((chainId: SupportedNonEvmChainId) => (
          <NonEvmChainSelectorRow
            onSelectChain={onSelectNonEvmChain}
            targetChain={chainId}
            key={chainId}
            // TODO: Check if isPending can be removed
            isPending={false}
          />
        ))}
      </Column>
    </NavDropdown>
  )

  const chevronProps = {
    height: 20,
    width: 20,
    color: theme.textSecondary,
  }

  return (
    <Box position="relative" ref={ref}>
      <Row
        as="button"
        gap="8"
        className={styles.ChainSelector}
        background={isOpen ? 'accentActiveSoft' : 'none'}
        data-testid="chain-selector"
        onClick={() => setIsOpen(!isOpen)}
      >
        {!isSupported ? (
          <>
            <TokenWarningRedIcon fill={themeVars.colors.textSecondary} width={24} height={24} />
            <Box as="span" className={subhead} display={showLabel ? 'flex' : 'none'} style={{ lineHeight: '20px' }}>
              Unsupported
            </Box>
          </>
        ) : (
          <>
            <img src={info.logoUrl} alt={info.label} className={styles.Image} />
            <Box as="span" className={subhead} display={showLabel ? 'flex' : 'none'} style={{ lineHeight: '20px' }}>
              {info.label}
            </Box>
          </>
        )}
        {isOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />}
      </Row>
      {isOpen && (isMobile ? <Portal>{dropdown}</Portal> : <>{dropdown}</>)}
    </Box>
  )
}

interface ChainSelectorDestinationChainProps {
  onSelectChain: (chainId: number) => void
  selectedChainId: SelectedChainId
  unavailableChainId: SelectedChainId
  leftAlign?: boolean
  showLabel?: boolean
}

/** Copy paste of `ChainSelector`. TODO: optimize those components */
export const ChainSelectorDestinationChain = ({
  onSelectChain,
  unavailableChainId,
  leftAlign,
  selectedChainId,
  showLabel,
}: ChainSelectorDestinationChainProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const isMobile = useIsMobile()

  const theme = useTheme()

  const ref = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsOpen(false), [modalRef])

  const onSelectChainHandler = useCallback(
    (targetChain: number) => {
      onSelectChain(targetChain)
      setIsOpen(false)
    },
    [onSelectChain]
  )

  const info =
    selectedChainId === SupportedNonEvmChainId.HYDRA
      ? getNonEvmChainInfo(SupportedNonEvmChainId.HYDRA)
      : getChainInfo(selectedChainId ?? undefined)

  const isSelected = !!info

  const dropdown = (
    <NavDropdown top="56" left={leftAlign ? '0' : 'auto'} right={leftAlign ? 'auto' : '0'} ref={modalRef}>
      <Column paddingX="8">
        {NETWORK_SELECTOR_CHAINS.filter((chainId) => chainId !== unavailableChainId).map(
          (chainId: SupportedChainId) => (
            <ChainSelectorRow
              onSelectChain={onSelectChainHandler}
              targetChain={chainId}
              key={chainId}
              isPending={false}
            />
          )
        )}
        {NON_EVM_NETWORK_SELECTOR_CHAINS.filter((chainId) => chainId !== unavailableChainId).map(
          (chainId: SupportedNonEvmChainId) => (
            <NonEvmChainSelectorRow
              onSelectChain={onSelectChainHandler}
              targetChain={chainId}
              key={chainId}
              isPending={false}
            />
          )
        )}
      </Column>
    </NavDropdown>
  )

  const chevronProps = {
    height: 20,
    width: 20,
    color: theme.textSecondary,
  }

  return (
    <Box position="relative" ref={ref}>
      <Row
        as="button"
        gap="8"
        className={styles.ChainSelector}
        background={isOpen ? 'accentActiveSoft' : 'none'}
        data-testid="chain-selector"
        onClick={() => setIsOpen(!isOpen)}
      >
        {!isSelected ? (
          <>
            <TokenWarningRedIcon fill={themeVars.colors.textSecondary} width={24} height={24} />
            <Box as="span" className={subhead} display={showLabel ? 'flex' : 'none'} style={{ lineHeight: '20px' }}>
              Select destination chain
            </Box>
          </>
        ) : (
          <>
            <img src={info.logoUrl} alt={info.label} className={styles.Image} />
            <Box as="span" className={subhead} display={showLabel ? 'flex' : 'none'} style={{ lineHeight: '20px' }}>
              {info.label}
            </Box>
          </>
        )}
        {isOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />}
      </Row>
      {isOpen && (isMobile ? <Portal>{dropdown}</Portal> : <>{dropdown}</>)}
    </Box>
  )
}
