import { SupportedChainId, SupportedNonEvmChainId } from 'constants/chains'
import { constructSameAddressMap } from '../utils/constructSameAddressMap'

type AddressMap = { [chainId: number]: string }

const BNB_MULTICALL_ADDRESS = '0x963Df249eD09c358A4819E39d9Cd5736c3087184'
const HYDRA_MULTICALL_ADDRESS = '0x3b42289638b68a7a7f61fb8729b3357694f2ba8e'
const HYDRAGON_MULTICALL_ADDRESS = '0xaAAcF23FEC7E846b14b84539D8d8629b527183Fa'

export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984'),
  [SupportedChainId.BNB]: BNB_MULTICALL_ADDRESS,
  [SupportedNonEvmChainId.HYDRA]: HYDRA_MULTICALL_ADDRESS,
  [SupportedChainId.HYDRAGON]: HYDRAGON_MULTICALL_ADDRESS,
}

const ETH_BRIDGE_ADDRESS = '0x06f0b83EaEd9aA9F94a9Bfe1b06b7Ef711f9E632'
const BNB_BRIDGE_ADDRESS = '0xb738623eEB0A686a2A6946d5a671e60CB4c1253d'
const POLYGON_BRIDGE_ADDRESS = '0xb738623eEB0A686a2A6946d5a671e60CB4c1253d'
const HYDRA_BRIDGE_ADDRESS = '0xd5ca56e3ef359e2b332a2a6cb7c3d7577e614c40'
const HYDRAGON_BRIDGE_ADDRESS = '0xb738623eEB0A686a2A6946d5a671e60CB4c1253d'

export const BRIDGE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: ETH_BRIDGE_ADDRESS,
  [SupportedChainId.BNB]: BNB_BRIDGE_ADDRESS,
  [SupportedChainId.POLYGON]: POLYGON_BRIDGE_ADDRESS,
  [SupportedNonEvmChainId.HYDRA]: HYDRA_BRIDGE_ADDRESS,
  [SupportedChainId.HYDRAGON]: HYDRAGON_BRIDGE_ADDRESS,
}

export const MULTICALL3_ADDRESS: AddressMap = {
  [SupportedNonEvmChainId.HYDRA]: '0x97c9022b08a461b67b04bcd90a81d3cd361e91aa',
}
