import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import type { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers'
import { base58 } from 'ethers/lib/utils'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    // Alphabetical letters must be made lowercase for getAddress to work.
    // See documentation here: https://docs.ethers.io/v5/api/utils/address/
    return getAddress(value.toLowerCase())
  } catch {
    return false
  }
}

export function hydraToHexAddress(address: string | undefined, noPrefix?: boolean): string | undefined {
  try {
    if (!address || address.length > 34) {
      return address
    }

    const hex = Buffer.from(base58.decode(address)).toString('hex').substring(2, 42)
    return noPrefix ? hex : '0x' + hex
  } catch {
    return undefined
  }
}

/** Checks if length is 34 and then converts hydra address to hex and calls `isAddress`.
 *  TODO: Improve or delete after HydraGon migration */
export function isHydraAddress(value: any): string | false {
  try {
    return value?.length === 34 ? isAddress(hydraToHexAddress(value)) : false
  } catch {
    return false
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

export function shortenTxHash(txHash: string, chars = 4): string {
  if (!txHash.startsWith('0x')) {
    txHash = '0x' + txHash
  }

  return `${txHash.substring(0, chars + 2)}...${txHash.substring(66 - chars)}`
}

// account is not optional
function getSigner(provider: JsonRpcProvider, account: string): JsonRpcSigner {
  return provider.getSigner(account).connectUnchecked()
}

// account is optional
function getProviderOrSigner(provider: JsonRpcProvider, account?: string): JsonRpcProvider | JsonRpcSigner {
  return account ? getSigner(provider, account) : provider
}

// account is optional
export function getContract(address: string, ABI: any, provider: JsonRpcProvider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(provider, account) as any)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}
