import { useWeb3React } from '@web3-react/core'
import { useHydraWeb3 } from 'lib/hooks/useHydraWeb3'
import { isHydraChainId } from 'lib/utils/hydra'
import { SelectedChainId } from 'types/chain'

export function useAddressForChainId(chainId: SelectedChainId): string | undefined {
  const { hydraAddress } = useHydraWeb3()
  const { account } = useWeb3React()

  if (!chainId) {
    return undefined
  }

  return isHydraChainId(chainId) ? hydraAddress : account
}
