import { chainIdToNetworkName, getNativeLogoURI } from 'lib/hooks/useCurrencyLogoURIs'
import { isHydraChainId } from 'lib/utils/hydra'
import { useCallback, useEffect, useState } from 'react'
import { isAddress } from 'utils'

function getInitialUrl(address?: string | null, chainId?: number | null, isNative?: boolean) {
  if (chainId && isNative) return getNativeLogoURI(chainId)

  const customSource = getCustomSource(address, chainId)
  if (customSource) {
    return customSource
  }

  const networkName = chainId ? chainIdToNetworkName(chainId) : 'ethereum'

  const checksummedAddress = isAddress(address)
  if (checksummedAddress) {
    return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${networkName}/assets/${checksummedAddress}/logo.png`
  } else {
    return undefined
  }
}

export default function useAssetLogoSource(
  address?: string | null,
  chainId?: number | null,
  isNative?: boolean
): [string | undefined, () => void] {
  const [current, setCurrent] = useState<string | undefined>(getInitialUrl(address, chainId, isNative))

  useEffect(() => {
    setCurrent(getInitialUrl(address, chainId, isNative))
  }, [address, chainId, isNative])

  const nextSrc = useCallback(() => {
    setCurrent(undefined)
  }, [])

  return [current, nextSrc]
}

function getCustomSource(address?: string | null, chainId?: number | null): string | undefined {
  if (!address || !chainId) {
    return undefined
  }

  if (isHydraChainId(chainId)) {
    return CUSTOM_LOGO_TABLE[address.toLowerCase() as keyof typeof CUSTOM_LOGO_TABLE]
  }

  const checksummedAddress = isAddress(address)
  if (checksummedAddress) {
    return CUSTOM_LOGO_TABLE[checksummedAddress as keyof typeof CUSTOM_LOGO_TABLE]
  }

  return undefined
}

const CUSTOM_LOGO_TABLE = {
  // ETHEREUM
  '0x96C3530BFd0a906a123A4e26CEbB635636B41f9d':
    'https://raw.githubusercontent.com/Hydra-Chain/hydraswap-token-list/main/icons/hydra-logo.png',
  '0x5e3346444010135322268a4630d2ED5F8D09446c':
    'https://raw.githubusercontent.com/weichain/hydraswap-token-list/main/icons/LockTrip_LOC_400x400.jpg',
  '0x7051faED0775f664a0286Af4F75ef5ed74e02754':
    'https://github.com/Hydra-Chain/hydraswap-token-list/raw/main/icons/changex-logo-round.png',
  '0xFB1172b050BcC798e37AE8abF620Cc528e771162':
    'https://raw.githubusercontent.com/Hydra-Chain/hydraswap-token-list/main/icons/Gomt.jpg',
  // POLYGON
  '0x3BA4c387f786bFEE076A58914F5Bd38d668B42c3':
    'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png',

  // HYDRA
  '0x6d9115a21863ce31b44cd231e4c4ccc87566222f':
    'https://raw.githubusercontent.com/Hydra-Chain/hydraswap-token-list/main/icons/hydra-logo.png',
  '0xab9e970de9ba9ec2c64a7f7f911da44b750ecf6a':
    'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  '0xabc2cd00700e06922bcf30fe0ad648507113cc56':
    'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png',
  '0x27a459ac47204f1e7fc5c48ffeafa990af244e13':
    'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
  '0x9cbbbc9d192fc81225a70f88877169b94d663f8b':
    'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x5aaEFe84E0fB3DD1f0fCfF6fA7468124986B91bd/logo.png',
  '0x4ab26aaa1803daa638910d71075c06386e391147':
    'https://raw.githubusercontent.com/weichain/hydraswap-token-list/main/icons/LockTrip_LOC_400x400.jpg',
  '0xbd3c617d271b3467bd9b83dda73c9288de2fb0c9':
    'https://github.com/Hydra-Chain/hydraswap-token-list/raw/main/icons/changex-logo-round.png',
  '0x7b8dd012ec9e1f11e008376ef21d29ba4cc78e24':
    'https://raw.githubusercontent.com/Hydra-Chain/hydraswap-token-list/main/icons/Gomt.jpg',
  '0xe4b8164dccf3489f66124cace1570dc35b58fc90':
    'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
  '0x932917c89717c809f67ebb421e24fd04d7a96d33':
    'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
  '0x0c404645b9e36b6cf9bcaf2747e472334b268e9d':
    'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x4Fabb145d64652a948d72533023f6E7A623C7C53/logo.png',

  // HYDRAGON
  '0x900E563a74BE93807E8A4A3B52D72A351baDd6bF':
    'https://raw.githubusercontent.com/Hydra-Chain/hydraswap-token-list/main/icons/hydra-logo.png',
}
