import { ButtonOutlined, ButtonPrimary } from 'components/Button'
import Row from 'components/Row'
import React, { memo, useCallback, useMemo } from 'react'

type PaginationProps = {
  totalPages: number
  currentPage: number
  onPageChange: (page: number) => void
  marginTop?: number
}

const NumberOfPagesAroundCurrent = 2

export const Pagination = memo(function Pagination(props: PaginationProps) {
  const pagesList = useMemo(() => {
    const pagesToShow = [1]

    // Pages around the current page
    const startPage = Math.max(2, props.currentPage - NumberOfPagesAroundCurrent)
    const endPage = Math.min(props.totalPages - 1, props.currentPage + NumberOfPagesAroundCurrent)

    for (let i = startPage; i <= endPage; i++) {
      pagesToShow.push(i)
    }

    pagesToShow.push(props.totalPages)

    return pagesToShow
  }, [props.totalPages, props.totalPages, props.currentPage])

  const handleClick = useCallback(
    (page: number) => {
      props.onPageChange(page)
    },
    [props.onPageChange]
  )

  return (
    <Row marginTop={props.marginTop} style={{ gap: '4px' }} justify="center">
      <PageButton onClick={() => handleClick(props.currentPage - 1)} disabled={props.currentPage === 1} text="<" />

      {pagesList.map((page, i) => (
        <React.Fragment key={page}>
          <PageButton
            key={page}
            text={page}
            onClick={() => handleClick(page)}
            isSelected={props.currentPage === page}
          />
          {i < pagesList.length - 1 && page + 1 !== pagesList[i + 1] ? <span>...</span> : null}
        </React.Fragment>
      ))}

      <PageButton
        onClick={() => handleClick(props.currentPage + 1)}
        disabled={props.currentPage === props.totalPages}
        text=">"
      />
    </Row>
  )
})

const PageButton = memo(function PageButton(props: {
  text: string | number
  onClick: () => void
  isSelected?: boolean
  disabled?: boolean
}) {
  const buttonStyle = useMemo(() => ({ fontSize: '14px', padding: '4px', width: '64px' }), [])

  return props.isSelected ? (
    <ButtonPrimary style={buttonStyle} disabled={props.disabled}>
      {props.text}
    </ButtonPrimary>
  ) : (
    <ButtonOutlined style={buttonStyle} disabled={props.disabled} onClick={props.onClick}>
      {props.text}
    </ButtonOutlined>
  )
})
