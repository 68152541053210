import { Token } from '@uniswap/sdk-core'
import { SupportedChainId } from 'constants/chains'

export const WHYDRA_HYDRAGON = new Token(
  SupportedChainId.HYDRAGON,
  '0x900E563a74BE93807E8A4A3B52D72A351baDd6bF',
  18,
  'WHYDRA',
  'Wrapped Hydra'
)
