export enum SupportedChainId {
  // Ethereum
  MAINNET = 1,
  GOERLI = 5,
  SEPOLIA = 11155111,

  // Binance Smart Chain
  BNB = 56,

  // Polygon
  POLYGON = 137,
  POLYGON_MUMBAI = 80001,

  // Hydra
  HYDRAGON = 4488,
}

export enum SupportedNonEvmChainId {
  // Hydra
  HYDRA = 8,
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.MAINNET]: 'mainnet',
  [SupportedChainId.GOERLI]: 'goerli',
  [SupportedChainId.SEPOLIA]: 'sepolia',

  [SupportedChainId.BNB]: 'bnb',

  [SupportedChainId.POLYGON]: 'polygon',
  [SupportedChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
  [SupportedChainId.HYDRAGON]: 'hydragon',
}

export const NON_EVM_CHAIN_IDS_TO_NAMES = {
  [SupportedNonEvmChainId.HYDRA]: 'hydra',
}

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number'
) as SupportedChainId[]

export function isSupportedChain(chainId: number | null | undefined): chainId is SupportedChainId {
  return !!chainId && !!SupportedChainId[chainId]
}

export const TESTNET_CHAIN_IDS = [
  SupportedChainId.GOERLI,
  SupportedChainId.SEPOLIA,
  SupportedChainId.POLYGON_MUMBAI,
] as const

export type SupportedTestnetChainId = typeof TESTNET_CHAIN_IDS[number]

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.GOERLI,
  SupportedChainId.SEPOLIA,
  SupportedChainId.BNB,
  SupportedChainId.POLYGON,
  SupportedChainId.HYDRAGON,
] as const

export const NON_EVM_CHAIN_IDS = [SupportedNonEvmChainId.HYDRA] as const

export const NETWORK_SELECTOR_CHAINS = [
  SupportedChainId.MAINNET,
  SupportedChainId.BNB,
  SupportedChainId.POLYGON,
  SupportedChainId.HYDRAGON,
]
export const NON_EVM_NETWORK_SELECTOR_CHAINS = [SupportedNonEvmChainId.HYDRA]
