import { CHAIN_FEES } from 'constants/fees'
import { nativeOnChain } from 'constants/tokens'
import { parseUnits } from 'ethers/lib/utils'
import { isHydraChainId } from 'lib/utils/hydra'
import { SelectedChainId } from 'types/chain'

export function getChainFee(chainId: SelectedChainId): string | null {
  if (!chainId) {
    return null
  }

  return CHAIN_FEES[chainId as keyof typeof CHAIN_FEES]
}

export function getNativeCurrencySymbol(chainId: SelectedChainId): string | null {
  if (!chainId) {
    return null
  }

  if (isHydraChainId(chainId)) {
    return 'HYDRA'
  }

  return nativeOnChain(chainId).symbol ?? null
}

export function getChainFeeParsed(chainId: SelectedChainId): string | null {
  const chainFee = getChainFee(chainId)
  if (!chainFee) {
    return null
  }

  return parseUnits(chainFee, isHydraChainId(chainId) ? 8 : 18).toString()
}
