import { useWeb3React } from '@web3-react/core'
import { TransactionSummary } from 'components/AccountDetailsV2'
import { ButtonPrimary } from 'components/Button'
import { useHydraWeb3 } from 'lib/hooks/useHydraWeb3'
import { isHydraChainId } from 'lib/utils/hydra'
import { useMemo } from 'react'
import { ChevronRight } from 'react-feather'
import { useToggleWalletModal } from 'state/application/hooks'
import { useAppSelector } from 'state/hooks'
import styled from 'styled-components/macro'
import { useAllTransactions } from '../../state/transactions/hooks'
import { AuthenticatedHeader, AuthenticatedHydraHeader } from './AuthenticatedHeader'
import { MenuState } from './index'

const ConnectButton = styled(ButtonPrimary)`
  border-radius: 12px;
  height: 44px;
  width: 288px;
  font-weight: 600;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: 100%;
  }
`

const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.backgroundOutline};
  margin-top: 16px;
  margin-bottom: 16px;
`

const ToggleMenuItem = styled.button`
  background-color: transparent;
  margin: 0;
  border: none;
  cursor: pointer;
  display: flex;
  flex: 1;
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  padding: 12px 8px;
  color: ${({ theme }) => theme.textSecondary};
  :hover {
    color: ${({ theme }) => theme.textPrimary};
    background-color: ${({ theme }) => theme.backgroundModule};
    transition: ${({
      theme: {
        transition: { duration, timing },
      },
    }) => `${duration.fast} all ${timing.in}`};
  }
`

const FlexContainer = styled.div`
  display: flex;
`

const LatestPendingTxnBox = styled(FlexContainer)`
  display: flex;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.backgroundModule};
  align-items: center;
  gap: 8px;
`

const PendingBadge = styled.span`
  background-color: ${({ theme }) => theme.accentActionSoft};
  color: ${({ theme }) => theme.accentAction};
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 4px;
`

const IconWrap = styled.span`
  display: inline-block;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 4px;
  height: 16px;
`

const DefaultMenuWrap = styled.div`
  width: 100%;
  height: 100%;
`

const DefaultText = styled.span`
  font-size: 14px;
  font-weight: 400;
`

const WalletDropdown = ({ setMenu }: { setMenu: (state: MenuState) => void }) => {
  const { account } = useWeb3React()
  const { hydraAddress } = useHydraWeb3()
  const isAuthenticatedEvm = !!account
  const isAuthenticatedHydra = !!hydraAddress
  const allTransactions = useAllTransactions()
  const toggleWalletModal = useToggleWalletModal()
  const selectedChainId = useAppSelector((state) => state.connection.selectedChainId)

  const pendingTransactions = useMemo(
    () => Object.values(allTransactions).filter((tx) => !tx.receipt),
    [allTransactions]
  )
  const latestPendingTransaction =
    pendingTransactions.length > 0
      ? pendingTransactions.sort((tx1, tx2) => tx2.addedTime - tx1.addedTime)[0]
      : undefined

  return (
    <DefaultMenuWrap>
      {isHydraChainId(selectedChainId) && isAuthenticatedHydra ? (
        <AuthenticatedHydraHeader />
      ) : !isHydraChainId(selectedChainId) && isAuthenticatedEvm ? (
        <AuthenticatedHeader />
      ) : (
        <ConnectButton data-testid="wallet-connect-wallet" onClick={toggleWalletModal}>
          Connect wallet
        </ConnectButton>
      )}
      <Divider />
      {isAuthenticatedEvm || isAuthenticatedHydra ? (
        <>
          <ToggleMenuItem data-testid="wallet-transactions" onClick={() => setMenu(MenuState.TRANSACTIONS)}>
            <DefaultText>
              Transactions{' '}
              {pendingTransactions.length > 0 && <PendingBadge>{pendingTransactions.length} Pending</PendingBadge>}
            </DefaultText>
            <IconWrap>
              <ChevronRight size={16} strokeWidth={3} />
            </IconWrap>
          </ToggleMenuItem>
          {!!latestPendingTransaction && (
            <LatestPendingTxnBox>
              <TransactionSummary
                key={latestPendingTransaction.hash}
                transactionDetails={latestPendingTransaction}
                isLastTransactionInList={true}
              />
            </LatestPendingTxnBox>
          )}
        </>
      ) : null}
    </DefaultMenuWrap>
  )
}

export default WalletDropdown
