import { Hydraweb3 } from 'ipetrov22-hydraweb3-js'
import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useConnectHydra } from 'state/application/hooks'

declare global {
  interface Window {
    hydrawallet: any
    ReactNativeWebView: any
    hasListener: boolean
  }
}

const defaultState = {
  walletExtension: {},
  hydraweb3Extension: {},
  error: '',
}

type HydraConnectorContextType = {
  walletExtension: any
  hydraweb3Extension: any
  error: string
}

export const HydraConnectorContext = createContext<HydraConnectorContextType>(defaultState)

export const HydraConnectorProvider = ({ children }: PropsWithChildren) => {
  const [walletExtension, setWalletExtension] = useState()
  const [hydraweb3Extension, setHydraweb3Extension] = useState()
  const [error, setError] = useState('')

  const handleEvents = useCallback((event: any) => {
    if (event.data.message && event.data.message.type === 'HYDRAWALLET_INSTALLED_OR_UPDATED') {
      // Refresh the page
      window.location.reload()
    }

    if (event.data.message && event.data.message.type === 'HYDRAWALLET_ACCOUNT_CHANGED') {
      if (event.data.message.payload.error) {
        // handle error
        setError(event.data.message.payload.error)
      }
      setError('')
      // should be in state where wallet or contract functions are used
      const walletExtension = event.data.message.payload.account
      const hydraweb3Extension = new Hydraweb3(window.hydrawallet.rpcProvider)

      setWalletExtension(walletExtension)
      setHydraweb3Extension(hydraweb3Extension)
    }

    if (event.data.target === 'hydrawallet-inpage') {
      // result: object
      // error: string
      const { error } = event.data.message.payload
      if (error) {
        if (error === 'Not logged in. Please log in to hydrawallet first.') {
          // Show an alert dialog that the user needs to login first
        } else {
          // Handle different error than not logged in...
        }
        return
      }

      if (event?.data?.payload?.statusChangeReason === 'Account Logged Out') {
        window.postMessage({ message: { type: 'CONNECT_HYDRAWALLET' } }, '')
      }
      // Do something with the message result...
    }
  }, [])

  const connectHydra = useConnectHydra()

  const connectWallet = useCallback(() => {
    connectHydra()
  }, [connectHydra])

  useEffect(() => {
    connectWallet()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (window.hydrawallet) {
      return
    }

    window.addEventListener('message', handleEvents, false)
    return () => window.removeEventListener('message', handleEvents)
  }, [handleEvents])

  return (
    <HydraConnectorContext.Provider value={{ walletExtension, hydraweb3Extension, error }}>
      {children}
    </HydraConnectorContext.Provider>
  )
}
