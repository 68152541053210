import { BigNumber } from '@ethersproject/bignumber'
import { hexlify, hexZeroPad } from '@ethersproject/bytes'
import { formatUnits, parseUnits } from '@ethersproject/units'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { HydraBridgeHydra__factory, Whydra__factory } from 'abis/types'
import AddressInputPanel from 'components/AddressInputPanel'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import HydraGuardWrapper from 'components/HydraGuardWrapper'
import Loader from 'components/Loader'
import { ChainSelector, ChainSelectorDestinationChain } from 'components/NavBar/ChainSelector'
import Row, { RowBetween } from 'components/Row'
import { WarningPopup } from 'components/WarningPopup'
import { WHYDRA_HYDRA } from 'constants/tokens/tokens_hydra'
import { TransactionConfirmationContext } from 'contexts/TransactionConfirmationContext'
import { useAccount } from 'hooks/useAccount'
import { useAddress } from 'hooks/useAddress'
import { useAddressForChainId } from 'hooks/useAddressForChainId'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useBridgeContract, useHydraBridgeContract, useHydraMulticall3Contract } from 'hooks/useContract'
import { useDestinationCurrencyFromOrigin } from 'hooks/useDestinationCurrencyFromOrigin'
import { useLiquidityForAsset } from 'hooks/useLiquidityForAsset'
import useSelectedChainId from 'hooks/useSelectedChainId'
import { useTokenAssetId } from 'hooks/useTokenAssetId'
import useTokensForChain from 'hooks/useTokensForChain'
import { useSingleCallResult } from 'lib/hooks/multicall'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import { contractSend, isHydraChainId } from 'lib/utils/hydra'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { SelectedChainId } from 'types/chain'
import { getBridgeAssetId } from 'utils/bridge'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { convertChainIdToBridgeChainId } from 'utils/convertChainIdToBridgeChainId'
import { getChainFee, getChainFeeParsed, getNativeCurrencySymbol } from 'utils/fees'

export default function Bridge() {
  const { setTransactionData } = useContext(TransactionConfirmationContext)

  const addTransaction = useTransactionAdder()
  const [attemptingTx, setAttemptingTx] = useState(false)

  const selectedOriginChainId = useSelectedChainId()
  const account = useAccount()
  const originTokens = useTokensForChain(selectedOriginChainId)
  const [inputAmount, setInputAmount] = useState<string>('')
  const [selectedOriginCurrency, setSelectedOriginCurrency] = useState<Currency | undefined>()
  const [selectedDestinationChainId, setSelectedDestinationChainId] = useState<SelectedChainId>(null)
  const [destinationAddressInput, setDestinationAddressInput] = useState<string>('')
  const destinationAddress = useAddress(destinationAddressInput, selectedDestinationChainId)
  const defaultAddressForDestinationChain = useAddressForChainId(selectedDestinationChainId)

  const destinationCurrency = useDestinationCurrencyFromOrigin(selectedOriginCurrency, selectedDestinationChainId)

  const inputAmountParsed = useMemo(
    () =>
      tryParseCurrencyAmount(
        inputAmount,
        selectedOriginCurrency?.isNative && isHydraChainId(selectedOriginCurrency.chainId)
          ? selectedOriginCurrency.wrapped
          : selectedOriginCurrency
      ),
    [inputAmount, selectedOriginCurrency]
  )
  const bridgeContract = useBridgeContract()
  const hydraBridgeContract = useHydraBridgeContract()
  const hydraMulticall3Contract = useHydraMulticall3Contract()

  const assetId = useTokenAssetId(selectedOriginCurrency)
  const vaultAddress = useSingleCallResult(
    isHydraChainId(selectedOriginChainId) ? hydraBridgeContract : bridgeContract,
    'assetIdToVault',
    [assetId],
    { blocksPerFetch: 0 }
  )?.result?.[0]

  const [approvalState, approve] = useApproveCallback(inputAmountParsed, vaultAddress)
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, selectedOriginCurrency ?? undefined)

  const liquidity = useLiquidityForAsset(selectedDestinationChainId, destinationCurrency?.wrapped?.address, assetId)
  const liquidityCurrencyAmount =
    destinationCurrency && liquidity
      ? CurrencyAmount.fromRawAmount(destinationCurrency, liquidity.toString())
      : undefined

  // If we haven't fetched liquidity don't allow transfers (even if requests keep failing)
  // Discussed with Nikola, this is to prevent users from attempting to bridge with insufficient liquidity and funds getting stuck
  // `liquidity !== undefined` because it's `null` if asset is mintable
  const isLiquidityEnough = liquidity !== undefined && getIsLiquidityEnough(inputAmountParsed, liquidityCurrencyAmount)

  const onUserInput = useCallback((val: string) => {
    setInputAmount(val)
  }, [])

  const onOriginCurrencySelect = useCallback((currency: Currency) => {
    setSelectedOriginCurrency(currency)
  }, [])

  const onSelectedDestinationChainId = useCallback((chainId: SelectedChainId) => {
    setSelectedDestinationChainId(chainId)
  }, [])

  const handleApprove = useCallback(() => {
    if (!isHydraChainId(selectedOriginChainId)) {
      setAttemptingTx(true)
    }

    approve()
      .then(() => setAttemptingTx(false))
      .catch(() => setAttemptingTx(false))
  }, [approve, selectedOriginChainId])

  const handleTransfer = useCallback(async () => {
    if (!selectedDestinationChainId || !selectedOriginCurrency || !inputAmount || !destinationAddress) {
      return
    }
    const bridgeAssetId = getBridgeAssetId(selectedOriginCurrency)
    if (!bridgeAssetId) {
      return
    }

    if (isHydraChainId(selectedOriginChainId)) {
      // hydra contract interaction
      if (!hydraBridgeContract || !account) {
        return
      }

      setTransactionData({
        showConfirm: true,
        attempting: true,
        pendingText: `Bridging asset...`,
        hash: '',
        txError: '',
      })

      try {
        const data =
          '0x' +
          hexZeroPad(
            BigNumber.from(parseUnits(inputAmount, selectedOriginCurrency.decimals).toString()).toHexString(),
            32
          ).substring(2) +
          hexZeroPad(hexlify(20), 32).substring(2) +
          destinationAddress.substring(2)

        const chainFee = getChainFeeParsed(selectedOriginChainId)

        if (!chainFee) {
          return
        }

        // `txValue` is `chainFee` + `amount` if native, otherwise just `chainFee`
        const txValue = selectedOriginCurrency.isNative
          ? BigNumber.from(chainFee).add(parseUnits(inputAmount, selectedOriginCurrency.decimals)).toString()
          : chainFee

        if (selectedOriginCurrency.isNative) {
          if (!hydraMulticall3Contract) {
            return
          }

          const wrapData = Whydra__factory.createInterface().encodeFunctionData('deposit')
          const depositData = HydraBridgeHydra__factory.createInterface().encodeFunctionData('deposit', [
            convertChainIdToBridgeChainId(selectedDestinationChainId),
            bridgeAssetId,
            data,
          ])

          const response = await contractSend(
            hydraMulticall3Contract,
            'aggregate3Value',
            [
              [
                {
                  target: WHYDRA_HYDRA.address,
                  allowFailure: false,
                  value: parseUnits(inputAmount, selectedOriginCurrency.decimals).toString(),
                  callData: wrapData,
                },
                {
                  target: hydraBridgeContract.address,
                  allowFailure: false,
                  value: chainFee,
                  callData: depositData,
                },
              ],
            ],
            account,
            350000,
            // Hydra hardcoded to 8 decimals
            Number(formatUnits(txValue, 8))
          )

          response.hash = response.txid

          addTransaction(response, {
            type: TransactionType.DEPOSIT,
            formattedAmount: inputAmount,
            tokenSymbol: selectedOriginCurrency.symbol ?? 'UNKNOWN',
          })

          setTransactionData((txData) => ({
            ...txData,
            showConfirm: true,
            attempting: false,
            hash: response.txid,
          }))

          return
        }

        const response = await contractSend(
          hydraBridgeContract,
          'deposit',
          [convertChainIdToBridgeChainId(selectedDestinationChainId), bridgeAssetId, data],
          account,
          300000,
          // Hydra hardcoded to 8 decimals
          Number(formatUnits(txValue, 8))
        )
        response.hash = response.txid

        addTransaction(response, {
          type: TransactionType.DEPOSIT,
          formattedAmount: inputAmount,
          tokenSymbol: selectedOriginCurrency.symbol ?? 'UNKNOWN',
        })

        setTransactionData((txData) => ({
          ...txData,
          showConfirm: true,
          attempting: false,
          hash: response.txid,
        }))
      } catch (error) {
        setTransactionData((txData) => ({
          ...txData,
          showConfirm: true,
          attempting: false,
          txError: error?.message ?? '',
        }))
        console.error(error)
      }
    } else {
      // evm contract interaction
      if (!bridgeContract) {
        return
      }

      setAttemptingTx(true)
      // TODO FIX: Pass fee and check if user has enough balance to cover the fee
      try {
        const data =
          '0x' +
          hexZeroPad(
            BigNumber.from(parseUnits(inputAmount, selectedOriginCurrency.decimals).toString()).toHexString(),
            32
          ).substring(2) +
          hexZeroPad(hexlify(20), 32).substring(2) +
          destinationAddress.substring(2)

        const chainFee = getChainFeeParsed(selectedOriginChainId)
        if (!chainFee) {
          return
        }

        // `txValue` is `chainFee` + `amount` if native, otherwise just `chainFee`
        const txValue = selectedOriginCurrency.isNative
          ? BigNumber.from(chainFee).add(parseUnits(inputAmount, selectedOriginCurrency.decimals)).toString()
          : chainFee

        const estimatedGas = await bridgeContract.estimateGas.deposit(
          convertChainIdToBridgeChainId(selectedDestinationChainId),
          bridgeAssetId,
          data,
          {
            value: txValue,
          }
        )
        const response = await bridgeContract.deposit(
          convertChainIdToBridgeChainId(selectedDestinationChainId),
          bridgeAssetId,
          data,
          {
            value: txValue,
            gasLimit: calculateGasMargin(estimatedGas),
          }
        )

        addTransaction(response, {
          type: TransactionType.DEPOSIT,
          formattedAmount: inputAmount,
          tokenSymbol: selectedOriginCurrency.symbol ?? 'UNKNOWN',
        })

        setAttemptingTx(false)
      } catch (error) {
        setAttemptingTx(false)
        console.error(error)
      }
    }
  }, [
    addTransaction,
    inputAmount,
    selectedOriginCurrency,
    selectedOriginChainId,
    selectedDestinationChainId,
    hydraBridgeContract,
    bridgeContract,
    hydraMulticall3Contract,
    destinationAddress,
    account,
  ])

  useEffect(() => {
    setSelectedOriginCurrency(undefined)
  }, [selectedOriginChainId])

  useEffect(() => {
    if (selectedOriginChainId === selectedDestinationChainId) {
      setSelectedDestinationChainId(null)
    }
  }, [selectedOriginChainId, selectedDestinationChainId])

  useEffect(() => {
    if (!destinationAddress && defaultAddressForDestinationChain) {
      setDestinationAddressInput(defaultAddressForDestinationChain)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAddressForDestinationChain])

  return (
    <HydraGuardWrapper>
      <AutoColumn gap="md" style={{ width: '100%', maxWidth: '450px' }}>
        <RowBetween marginBottom={-10}>
          <ChainSelector showLabel={true} leftAlign={true} />
          <FeeContainer />
        </RowBetween>
        <CurrencyInputPanel
          id="origin"
          value={inputAmount}
          showMaxButton={false}
          currency={selectedOriginCurrency ?? null}
          onUserInput={onUserInput}
          onCurrencySelect={onOriginCurrencySelect}
          tokenList={originTokens ? Object.values(originTokens) : undefined}
        />

        <Row marginBottom={-10}>
          <ChainSelectorDestinationChain
            onSelectChain={onSelectedDestinationChainId}
            selectedChainId={selectedDestinationChainId}
            unavailableChainId={selectedOriginChainId}
            showLabel={true}
            leftAlign={true}
          />

          {/* If not enough liquidity - show how much is available */}
          {liquidity && destinationCurrency && inputAmountParsed && !isLiquidityEnough ? (
            <Text marginLeft={1}>
              Available: {formatUnits(liquidity, destinationCurrency.decimals)} {destinationCurrency.symbol}
            </Text>
          ) : null}
        </Row>

        <CurrencyInputPanel
          id="destination"
          value={inputAmount}
          currency={destinationCurrency ?? null}
          showMaxButton={false}
          // We don't want input from the destination field
          onUserInput={() => undefined}
          inputDisabled
          hideBalance
          selectCurrencyText={
            selectedOriginCurrency && selectedDestinationChainId && !destinationCurrency ? 'Unsupported' : undefined
          }
        />

        <div style={{ position: 'relative' }}>
          <AddressInputPanel
            label="Destination wallet address"
            value={destinationAddressInput}
            onChange={setDestinationAddressInput}
            chainId={selectedDestinationChainId}
          />
          <WarningPopup
            style={{
              position: 'absolute',
              zIndex: 1,
              top: '10px',
              right: '10px',
            }}
            warningText="Please do not use smart contracts addresses as swap recipients. This can result in loss of funds. We highly recommend to swap only to wallets that you have direct access to."
          />
        </div>

        {approvalState !== ApprovalState.APPROVED && inputAmountParsed ? (
          <ButtonPrimary onClick={handleApprove} disabled={approvalState === ApprovalState.PENDING || attemptingTx}>
            {approvalState === ApprovalState.PENDING || attemptingTx ? 'Approving...' : 'Approve'}
          </ButtonPrimary>
        ) : (
          <ButtonPrimary
            onClick={handleTransfer}
            disabled={
              inputAmountParsed && selectedCurrencyBalance && !attemptingTx && isLiquidityEnough
                ? // !(isValid)
                  !(
                    inputAmountParsed?.greaterThan(0) &&
                    !inputAmountParsed?.greaterThan(selectedCurrencyBalance) &&
                    destinationCurrency &&
                    destinationAddress
                  )
                : true
            }
          >
            {attemptingTx ? <Loader size="24px" /> : 'Transfer'}
          </ButtonPrimary>
        )}
      </AutoColumn>
    </HydraGuardWrapper>
  )
}

const FeeContainer = memo(function FeeContainer() {
  const selectedChainId = useSelectedChainId()
  return (
    <Row flex={1} justify="flex-end" paddingRight={10}>
      <Text fontWeight={600} marginRight={1}>
        Fee:
      </Text>
      <Text marginRight={1}>
        {getChainFee(selectedChainId)} {getNativeCurrencySymbol(selectedChainId)}
      </Text>
    </Row>
  )
})

// Utils
function getIsLiquidityEnough(
  inputAmount: CurrencyAmount<Currency> | undefined,
  liquidity: CurrencyAmount<Currency> | undefined
) {
  if (!inputAmount || !liquidity) {
    return true
  }

  try {
    return (
      Number(liquidity.toFixed(liquidity.currency.decimals)) >=
      Number(inputAmount.toFixed(inputAmount.currency.decimals))
    )
  } catch {
    return true
  }
}
