import { Currency } from '@uniswap/sdk-core'
import { isHydraChainId } from 'lib/utils/hydra'
import { SelectedChainId } from 'types/chain'
import useTokensForChain from './useTokensForChain'

export function useDestinationCurrencyFromOrigin(
  originCurrency: Currency | undefined,
  destinationChainId: SelectedChainId
): Currency | undefined {
  const destinationTokens = useTokensForChain(destinationChainId)
  if (!originCurrency) {
    return undefined
  }

  const currency = destinationTokens
    ? destinationTokens[originCurrency.wrapped.symbol as keyof typeof destinationTokens] ||
      // For example: Input is WMATIC on ETH -> Output should be MATIC on Polygon
      Object.values(destinationTokens).find(
        (token) =>
          token.wrapped.symbol === originCurrency.wrapped.symbol ||
          // Cover HYDRA -> WHYDRA, WHYDRA -> HYDRA cases. F.e. we want to show WHYDRA on ETH as HYDRA.
          token.wrapped.symbol?.substring(1) === originCurrency.wrapped.symbol ||
          token.wrapped.symbol === originCurrency.wrapped.symbol?.substring(1)
      )
    : undefined

  // We currently do not support native hydra unwrap
  return currency && isHydraChainId(currency.chainId) ? currency.wrapped : currency
}
