import { MaxUint256 } from '@ethersproject/constants'
import type { TransactionResponse } from '@ethersproject/providers'
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { TransactionConfirmationContext } from 'contexts/TransactionConfirmationContext'
import { useAccount } from 'hooks/useAccount'
import { useHydraTokenContract, useTokenContract } from 'hooks/useContract'
import useSelectedChainId from 'hooks/useSelectedChainId'
import { useTokenAllowance } from 'hooks/useTokenAllowance'
import { contractSend, isHydraChainId } from 'lib/utils/hydra'
import { useCallback, useContext, useMemo } from 'react'
import { calculateGasMargin } from 'utils/calculateGasMargin'

export enum ApprovalState {
  UNKNOWN = 'UNKNOWN',
  NOT_APPROVED = 'NOT_APPROVED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

function useApprovalStateForSpender(
  amountToApprove: CurrencyAmount<Currency> | undefined,
  spender: string | undefined,
  useIsPendingApproval: (token?: Token, spender?: string) => boolean
): ApprovalState {
  const account = useAccount()
  const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined

  const { tokenAllowance } = useTokenAllowance(token, account ?? undefined, spender)
  const pendingApproval = useIsPendingApproval(token, spender)

  return useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    if (amountToApprove.currency.isNative) return ApprovalState.APPROVED
    // we might not have enough data to know whether or not we need to approve
    if (!tokenAllowance) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if tokenAllowance is
    return tokenAllowance.lessThan(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, pendingApproval, spender, tokenAllowance])
}

export function useApproval(
  amountToApprove: CurrencyAmount<Currency> | undefined,
  spender: string | undefined,
  useIsPendingApproval: (token?: Token, spender?: string) => boolean
): [
  ApprovalState,
  () => Promise<{ response: TransactionResponse; tokenAddress: string; spenderAddress: string } | undefined>
] {
  const { setTransactionData } = useContext(TransactionConfirmationContext)
  const account = useAccount()
  const chainId = useSelectedChainId()
  const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined

  // check the current approval status
  const approvalState = useApprovalStateForSpender(amountToApprove, spender, useIsPendingApproval)

  const tokenContract = useTokenContract(token?.address)
  const hydraTokenContract = useHydraTokenContract(token?.address)

  const approve = useCallback(async () => {
    function logFailure(error: Error | string): undefined {
      console.warn(`${token?.symbol || 'Token'} approval failed:`, error)
      return
    }

    // Bail early if there is an issue.
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      return logFailure('approve was called unnecessarily')
    } else if (!chainId) {
      return logFailure('no chainId')
    } else if (!token) {
      return logFailure('no token')
    } else if (!amountToApprove) {
      return logFailure('missing amount to approve')
    } else if (!spender) {
      return logFailure('no spender')
    }

    if (isHydraChainId(chainId)) {
      if (!hydraTokenContract) {
        return logFailure('tokenContract is null')
      } else if (!account) {
        return logFailure('no account')
      }

      setTransactionData({
        showConfirm: true,
        attempting: true,
        pendingText: `Approving token...`,
        hash: '',
        txError: '',
      })

      return contractSend(hydraTokenContract, 'approve', [spender, MaxUint256], account)
        .then((response) => {
          response.hash = response.txid
          setTransactionData((txData) => ({
            ...txData,
            showConfirm: true,
            attempting: false,
            hash: response.hash,
          }))

          return {
            response,
            tokenAddress: token.address,
            spenderAddress: spender,
          }
        })
        .catch((error) => {
          setTransactionData((txData) => ({
            ...txData,
            showConfirm: true,
            attempting: false,
            txError: error?.message ?? '',
          }))

          logFailure(error)
          throw error
        })
    } else {
      if (!tokenContract) {
        return logFailure('tokenContract is null')
      }

      const estimatedGas = await tokenContract.estimateGas.approve(spender, MaxUint256).catch(() => {
        return tokenContract.estimateGas.approve(spender, amountToApprove.quotient.toString())
      })

      return tokenContract
        .approve(spender, MaxUint256, {
          gasLimit: calculateGasMargin(estimatedGas),
        })
        .then((response) => {
          return {
            response,
            tokenAddress: token.address,
            spenderAddress: spender,
          }
        })
        .catch((error: Error) => {
          logFailure(error)
          throw error
        })
    }
  }, [approvalState, token, tokenContract, amountToApprove, spender, chainId, account, hydraTokenContract])

  return [approvalState, approve]
}
