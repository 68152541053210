import { Interface } from '@ethersproject/abi'
import { Contract } from '@ethersproject/contracts'
import { useWeb3React } from '@web3-react/core'
import ERC20_ABI from 'abis/erc20.json'
import ERC20_BYTES32_ABI from 'abis/erc20_bytes32.json'
import HYDRA_BRIDGE_EVM_ABI from 'abis/hydra-bridge_evm.json'
import HYDRA_BRIDGE_HYDRA_ABI from 'abis/hydra-bridge_hydra.json'
import { Erc20, HydraBridgeEvm, Multicall3__factory, UniswapInterfaceMulticall } from 'abis/types'
import MulticallABI from 'abis/uniswap-interface-multicall.json'
import { BRIDGE_ADDRESS, MULTICALL3_ADDRESS, MULTICALL_ADDRESS } from 'constants/addresses'
import { SupportedNonEvmChainId } from 'constants/chains'
import { useHydraWeb3 } from 'lib/hooks/useHydraWeb3'
import { getHydraContract } from 'lib/utils/hydra'
import { useMemo } from 'react'
import { getContract } from '../utils'

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { provider, account, chainId } = useWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, provider, chainId, withSignerIfPossible, account]) as T
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(MULTICALL_ADDRESS, MulticallABI, false) as UniswapInterfaceMulticall
}

export function useBridgeContract(withSignerIfPossible?: boolean) {
  return useContract<HydraBridgeEvm>(BRIDGE_ADDRESS, HYDRA_BRIDGE_EVM_ABI, withSignerIfPossible) as HydraBridgeEvm
}

// Hydra contracts
export function useHydraContract(address: string, abi: any[]) {
  const { hydraProvider } = useHydraWeb3()

  const contract = useMemo(
    () => (hydraProvider && address ? getHydraContract(hydraProvider, address, abi) : null),
    [hydraProvider, address, abi]
  )

  if (contract) {
    contract.interface = new Interface(abi)
  }

  return contract
}

export function useHydraMulticallContract() {
  return useHydraContract(MULTICALL_ADDRESS[SupportedNonEvmChainId.HYDRA], MulticallABI)
}

export function useHydraBridgeContract() {
  return useHydraContract(BRIDGE_ADDRESS[SupportedNonEvmChainId.HYDRA], HYDRA_BRIDGE_HYDRA_ABI)
}

export function useHydraTokenContract(tokenAddress?: string) {
  return useHydraContract(tokenAddress ?? '', ERC20_ABI)
}

export function useHydraMulticall3Contract() {
  return useHydraContract(MULTICALL3_ADDRESS[SupportedNonEvmChainId.HYDRA], Multicall3__factory.abi)
}
