import { useWeb3React } from '@web3-react/core'
import { useHydraWeb3 } from 'lib/hooks/useHydraWeb3'
import { isHydraChainId } from 'lib/utils/hydra'
import useSelectedChainId from './useSelectedChainId'

export function useAccount() {
  const selectedChainId = useSelectedChainId()
  const { account: evmAccount } = useWeb3React()
  const { hydraHexAddress } = useHydraWeb3()
  return isHydraChainId(selectedChainId) ? hydraHexAddress : evmAccount
}
