import { NativeCurrency, Token } from '@uniswap/sdk-core'
import { SupportedChainId, SupportedNonEvmChainId } from 'constants/chains'
import { nativeOnChain } from 'constants/tokens'
import { DAI_BNB, MATIC_BNB, USDC_BNB, USDT_BNB, WBTC_BNB, WETH_BNB } from './tokens_bsc'
import {
  BNB_ETH,
  CHANGE_ETH,
  DAI_ETH,
  LOC_ETH,
  MATIC_ETH,
  USDC_ETH,
  USDT_ETH,
  WBTC_ETH,
  WHYDRA_ETH,
} from './tokens_eth'
import { CHANGE_HYDRA, DAI_HYDRA, LOC_HYDRA, USDC_HYDRA, USDT_HYDRA, WBTC_HYDRA, WETH_HYDRA } from './tokens_hydra'
import { BNB_POLYGON, DAI_POLYGON, USDC_POLYGON, USDT_POLYGON, WBTC_POLYGON, WETH_POLYGON } from './tokens_polygon'
import { TokenName } from './token_names'

// Using `Partial` because not all keys are mandatory
type TokenList = Partial<{
  [key in SupportedChainId | SupportedNonEvmChainId]: Partial<{
    [tokenName in TokenName]: Token | NativeCurrency
  }>
}>

export const SUPPORTED_TOKENS_PER_CHAIN: TokenList = {
  [SupportedChainId.MAINNET]: {
    HYDRA: WHYDRA_ETH,
    WETH: nativeOnChain(SupportedChainId.MAINNET),
    DAI: DAI_ETH,
    WBTC: WBTC_ETH,
    // EVED: EVED_ETH,
    LOC: LOC_ETH,
    CHANGE: CHANGE_ETH,
    // GOMT: GOMT_ETH,
    USDC: USDC_ETH,
    USDT: USDT_ETH,
    // BUSD: BUSD_ETH,
    BNB: BNB_ETH,
    MATIC: MATIC_ETH,
  },
  [SupportedChainId.BNB]: {
    WETH: WETH_BNB,
    DAI: DAI_BNB,
    WBTC: WBTC_BNB,
    USDC: USDC_BNB,
    USDT: USDT_BNB,
    // BUSD: BUSD_BNB,
    BNB: nativeOnChain(SupportedChainId.BNB),
    MATIC: MATIC_BNB,
  },
  [SupportedChainId.POLYGON]: {
    WETH: WETH_POLYGON,
    DAI: DAI_POLYGON,
    WBTC: WBTC_POLYGON,
    USDC: USDC_POLYGON,
    USDT: USDT_POLYGON,
    // BUSD: BUSD_POLYGON,
    BNB: BNB_POLYGON,
    MATIC: nativeOnChain(SupportedChainId.POLYGON),
  },
  [SupportedNonEvmChainId.HYDRA]: {
    HYDRA: nativeOnChain(SupportedNonEvmChainId.HYDRA),
    WETH: WETH_HYDRA,
    DAI: DAI_HYDRA,
    WBTC: WBTC_HYDRA,
    // EVED: EVED_HYDRA,
    LOC: LOC_HYDRA,
    CHANGE: CHANGE_HYDRA,
    // GOMT: GOMT_HYDRA,
    USDC: USDC_HYDRA,
    USDT: USDT_HYDRA,
    // BUSD: BUSD_HYDRA,
  },
  [SupportedChainId.HYDRAGON]: {
    HYDRA: nativeOnChain(SupportedChainId.HYDRAGON),
  },
}

export const TOKENS_BRIDGE_ASSET_ID: Record<string, string> = {
  WETH: '0x0000000000000000000000000000000000000000000000000000000000000000',
  DAI: '0x0000000000000000000000000000000000000000000000000000000000000001',
  WBTC: '0x0000000000000000000000000000000000000000000000000000000000000002',
  EVED: '0x0000000000000000000000000000000000000000000000000000000000000003',
  LOC: '0x0000000000000000000000000000000000000000000000000000000000000005',
  CHANGE: '0x0000000000000000000000000000000000000000000000000000000000000006',
  GOMT: '0x0000000000000000000000000000000000000000000000000000000000000007',
  USDC: '0x0000000000000000000000000000000000000000000000000000000000000008',
  USDT: '0x0000000000000000000000000000000000000000000000000000000000000009',
  BUSD: '0x0000000000000000000000000000000000000000000000000000000000000010',
  WHYDRA: '0x0000000000000000000000000000000000000000000000000000000000000011',
  HYDRA: '0x0000000000000000000000000000000000000000000000000000000000000011',
  WBNB: '0x0000000000000000000000000000000000000000000000000000000000000056',
  WMATIC: '0x0000000000000000000000000000000000000000000000000000000000000137',
}
