import { SUPPORTED_TOKENS_PER_CHAIN } from 'constants/tokens/index'
import { SelectedChainId } from 'types/chain'

export default function useTokensForChain(chainId: SelectedChainId) {
  if (!chainId) {
    return null
  }

  return SUPPORTED_TOKENS_PER_CHAIN[chainId as keyof typeof SUPPORTED_TOKENS_PER_CHAIN] || null
}
