import { Currency } from '@uniswap/sdk-core'
import { TOKENS_BRIDGE_ASSET_ID } from 'constants/tokens/index'

export function getBridgeAssetId(selectedOriginCurrency: Currency | undefined): string | undefined {
  if (!selectedOriginCurrency) {
    return undefined
  }

  return TOKENS_BRIDGE_ASSET_ID[selectedOriginCurrency.wrapped.symbol as keyof typeof TOKENS_BRIDGE_ASSET_ID]
}
