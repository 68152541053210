import bnbCircleLogoUrl from 'assets/images/bnbCircle.png'
import ethereumLogoUrl from 'assets/images/ethereum-logo.png'
import hydraLogo from 'assets/images/hydra-logo.png'
import bnbLogo from 'assets/svg/bnb-logo.svg'
import polygonMaticLogoUrl from 'assets/svg/polygon-matic-logo.svg'
import { SupportedChainId, SupportedNonEvmChainId } from 'constants/chains'
import ms from 'ms.macro'
import { darkTheme } from 'theme/colors'

export const AVERAGE_L1_BLOCK_TIME = ms`12s`

interface BaseChainInfo {
  readonly blockWaitMsBeforeWarning?: number
  readonly explorer: string
  readonly logoUrl: string
  readonly circleLogoUrl?: string
  readonly label: string
  readonly nativeCurrency: {
    name: string // e.g. 'Goerli ETH',
    symbol: string // e.g. 'gorETH',
    decimals: number // e.g. 18,
  }
  readonly color?: string
  readonly backgroundColor?: string
}

type ChainInfoMap = {
  readonly [chainId in SupportedChainId]: BaseChainInfo
}

const CHAIN_INFO: ChainInfoMap = {
  [SupportedChainId.MAINNET]: {
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    color: darkTheme.chain_1,
  },
  [SupportedChainId.GOERLI]: {
    explorer: 'https://goerli.etherscan.io/',
    label: 'Görli',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Görli Ether', symbol: 'görETH', decimals: 18 },
    color: darkTheme.chain_5,
  },
  [SupportedChainId.SEPOLIA]: {
    explorer: 'https://sepolia.etherscan.io/',
    label: 'Sepolia',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Sepolia Ether', symbol: 'SepoliaETH', decimals: 18 },
    color: darkTheme.chain_5,
  },
  [SupportedChainId.BNB]: {
    blockWaitMsBeforeWarning: ms`10m`,
    explorer: 'https://bscscan.com/',
    label: 'BNB Chain',
    logoUrl: bnbLogo,
    circleLogoUrl: bnbCircleLogoUrl,
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    color: darkTheme.chain_56,
    backgroundColor: darkTheme.chain_56_background,
  },
  [SupportedChainId.POLYGON]: {
    explorer: 'https://polygonscan.com/',
    label: 'Polygon',
    logoUrl: polygonMaticLogoUrl,
    // TODO check if MATIC is correct (they were migrating to something)
    nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
    color: darkTheme.chain_137,
    backgroundColor: darkTheme.chain_137_background,
  },
  [SupportedChainId.POLYGON_MUMBAI]: {
    explorer: 'https://mumbai.polygonscan.com/',
    label: 'Polygon Mumbai',
    logoUrl: polygonMaticLogoUrl,
    nativeCurrency: { name: 'Polygon Mumbai Matic', symbol: 'mMATIC', decimals: 18 },
    color: darkTheme.chain_137,
    backgroundColor: darkTheme.chain_137_background,
  },
  [SupportedChainId.HYDRAGON]: {
    explorer: 'https://skynet.hydrachain.org/',
    label: 'HydraGon',
    logoUrl: hydraLogo,
    nativeCurrency: { name: 'Hydra', symbol: 'HYDRA', decimals: 18 },
    color: darkTheme.chain_8,
    backgroundColor: darkTheme.chain_8_background,
  },
}

type NonEvmChainInfoMap = {
  readonly [chainId in SupportedNonEvmChainId]: BaseChainInfo
}

const NON_EVM_CHAIN_INFO: NonEvmChainInfoMap = {
  [SupportedNonEvmChainId.HYDRA]: {
    explorer: 'https://explorer.hydrachain.org/',
    label: 'Hydra',
    logoUrl: hydraLogo,
    nativeCurrency: { name: 'Hydra', symbol: 'HYDRA', decimals: 8 },
    color: darkTheme.chain_8,
    backgroundColor: darkTheme.chain_8_background,
  },
}

export function getChainInfo(chainId: SupportedChainId): BaseChainInfo
export function getChainInfo(chainId: SupportedChainId | number | undefined): BaseChainInfo | undefined

export function getChainInfo(chainId: any): any {
  if (chainId) {
    // @ts-ignore - if `chainId` is not supported it will just fallback to `undefined`
    return CHAIN_INFO[chainId] ?? undefined
  }
  return undefined
}

export function getNonEvmChainInfo(chainId: SupportedNonEvmChainId): BaseChainInfo
export function getNonEvmChainInfo(chainId: SupportedNonEvmChainId | number | undefined): BaseChainInfo | undefined

export function getNonEvmChainInfo(chainId: any): any {
  if (chainId) {
    // @ts-ignore - if `chainId` is not supported it will just fallback to `undefined`
    return NON_EVM_CHAIN_INFO[chainId] ?? undefined
  }
  return undefined
}

const MAINNET_INFO = CHAIN_INFO[SupportedChainId.MAINNET]
export function getChainInfoOrDefault(chainId: number | undefined) {
  return getChainInfo(chainId) ?? MAINNET_INFO
}

// Non EVM chain config is duplicate of EVM chain config to avoid breaking the existing format.
// TODO: Remove Non EVM logic after Hydra migration to HydraGon is complete
const HYDRA_INFO = NON_EVM_CHAIN_INFO[SupportedNonEvmChainId.HYDRA]
export function getNonEvmChainInfoOrDefault(chainId: number | undefined) {
  return getNonEvmChainInfo(chainId) ?? HYDRA_INFO
}
